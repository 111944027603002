import React, { useEffect, useState, useCallback, useRef } from "react";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { PageContainer } from "../../components/Layout";
import {
  HeaderContainer,
  CurrentEncontroContainer,
  Arrow,
  EncontroText,
  NavigationContainer,
  MenuItem,
  ResumoIcon,
  TechIcon,
  MedIcon,
  NutriIcon,
  TrainingIcon,
  PsicoIcon,
  EncontrosContainer,
  EncontroLine,
  Encontro,
} from "./styles";
import { selectCurrentClient } from "../../redux/ducks/Client/selectors";
import { selectLoading } from "../../redux/ducks/Anamnesis/selectors";
import { getAllAnamnesis } from "../../redux/ducks/Anamnesis/actions";
import SystemContext from "../../context/SystemContext";
import ClientContext from "../../context/ClientContext";
import ChallengeService from "../../services/ChallengeService";
import AnamnesisService from "../../services/AnamnesisService";
import GoalService from "../../services/GoalService";
import PDFService from "../../services/PDFService";

import Clients from "../Clients";
import Tech from "./Tech/index";
import Nutri from "./Nutri";
import Med from "./Med";
import ExamRequest from "./others/ExamRequest";
import Training from "./Training";
import ParQ from "./ParQ";
import Psico from "./Psico";
import Developing from "./Developing";
import { Loading } from "../../components";

const RESUME_ROUTE = "resume";
const TECH_ROUTE = "tech";
const MED_ROUTE = "med";
const NUTRI_ROUTE = "nutri";
const TRAINING_ROUTE = "training";
const PSICO_ROUTE = "psico";
const PARQ_ROUTE = "parq";

const Anamnesis = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [encontrosToggled, setEncontrosToggled] = useState(false);
  const loading = useSelector(selectLoading);
  const client = useSelector(selectCurrentClient);

  const queueRef = useRef({});

  //Context
  const [anamnesisType, setAnamnesisType] = useState();
  const [anamnesis, setAnamnesis] = useState([]);
  const [currentAnamnesis, setCurrentAnamnesis] = useState({});
  const [selectedIndexAnamnesis, setSelectedIndexAnamnesis] = useState();
  const [saving, setSaving] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [acceptedChallenges, setAcceptedChallenges] = useState([]);
  const [goal, setGoal] = useState({});

  const saveAnamnesisSync = useCallback(
    async (values, anamnesisType) => {
      setSaving(true);
      try {
        await AnamnesisService.insertAnamnesis(anamnesisType, values);
        // if (anamnesis === "nutri" && !data._id) {
        //   yield call(MealsService.generateWeekMeals, data.client);
        // }
      } catch (e) {
        NotificationManager.error(
          `${anamnesisType}`,
          "Erro ao salvar ficha de anamnese"
        );
      }
      setSaving(false);
    },
    [setSaving]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnamnesis = useCallback(
    _.debounce((values, anamnesisType) => {
      if (queueRef.current[anamnesisType]) {
        clearTimeout(queueRef.current[anamnesisType]);
      }

      const timeout = setTimeout(async () => {
        saveAnamnesisSync(values, anamnesisType);
      }, 1000);

      queueRef.current[anamnesisType] = timeout;
    }, 500),
    [saveAnamnesisSync]
  );

  const createAnamesis = useCallback(async () => {
    if (anamnesisType !== "tech") return;
    await saveAnamnesisSync({ form: { client: client._id } }, anamnesisType);
    getAnamnesis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, anamnesisType]);

  useEffect(() => {
    if (!client) return;
    let current = anamnesis[selectedIndexAnamnesis];
    setCurrentAnamnesis(current);
    if (current && !current._id) {
      createAnamesis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndexAnamnesis, anamnesis, setCurrentAnamnesis, client]);

  //GetChallenges
  useEffect(() => {
    async function getChallenges() {
      const response = await ChallengeService.getAll();
      const challenges = _.get(response, ["data", "data", "items"], []);
      setChallenges(challenges);
    }

    getChallenges();
  }, []);

  const downloadReport = useCallback(async () => {
    try {
      const reponse = await PDFService.generateReport(
        client._id,
        currentAnamnesis._id
      );
      window.open(URL.createObjectURL(reponse.data));
    } catch (e) {}
  }, [client, currentAnamnesis]);

  const updateGoal = async (values) => {
    const {
      leisureTimeActivity,
      workActivity,
      weight,
      goalWeight,
      height,
      imc,
    } = values;

    try {
    } catch (e) {}
    const response = await GoalService.calculate(
      client._id,
      leisureTimeActivity,
      workActivity,
      weight,
      goalWeight,
      height,
      imc
    );
    const goalSuggestion = response.data.data;
    setGoal(goalSuggestion);
  };

  useEffect(() => {
    async function getAcceptedChallenges() {
      let response = await ChallengeService.getAccepted(client._id);
      const accepted = _.get(response, ["data", "data"], []);
      setAcceptedChallenges(accepted.map((c) => c.challenge._id));
    }

    async function getGoal() {
      const response = await GoalService.getLast(client._id);
      const data = _.get(response, ["data", "data"], {});
      setGoal(data);
    }

    if (client) {
      getAcceptedChallenges();
      getGoal();
    }
  }, [client]);

  const getAnamnesis = useCallback(async () => {
    try {
      let response = await AnamnesisService.getAllAnamnesis(
        anamnesisType,
        client._id
      );
      let list = _.get(response, ["data", "data", "items"], []).reverse();
      const index = list.length - 1;
      if (list.length > 0) {
        const { _id, ...lastItemData } = list[list.length - 1];
        list.push(lastItemData);
      } else {
        list.push({});
      }

      const minListLength = 6;
      if (list.length < minListLength) {
        for (let i = list.length; i < minListLength; i++) {
          list.push({ disabled: true });
        }
      }

      setAnamnesis(list);
      setSelectedIndexAnamnesis(index);
    } catch (e) {}
  }, [client, anamnesisType]);

  useEffect(() => {
    if (client && anamnesisType) {
      getAnamnesis();
    }
  }, [anamnesisType, client, getAnamnesis]);

  useEffect(() => {
    if (!_.isEmpty(client)) {
      const anamnesisType = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
      );
      dispatch(getAllAnamnesis(anamnesisType, client._id));
    }
  }, [location, client, dispatch]);

  useEffect(() => {
    if (loading === false && !_.isEmpty(client)) {
      const anamnesisType = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
      );
      dispatch(getAllAnamnesis(anamnesisType, client._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getColor = () => {
    return `var(--${anamnesisType || "purple-dark"})`;
  };

  const renderNavigation = () => (
    <HeaderContainer>
      <NavigationContainer>
        <MenuItem
          color="var(--purple-dark)"
          selected={location.pathname.includes(RESUME_ROUTE)}
          onClick={() => history.push(RESUME_ROUTE)}
        >
          <ResumoIcon selected={location.pathname.includes(RESUME_ROUTE)} />
          Resumo
        </MenuItem>
        <MenuItem
          color="var(--tech)"
          selected={location.pathname.includes(TECH_ROUTE)}
          onClick={() => history.push(TECH_ROUTE)}
        >
          <TechIcon selected={location.pathname.includes(TECH_ROUTE)} />
          Tech
        </MenuItem>
        <MenuItem
          color="var(--med)"
          selected={location.pathname.includes(MED_ROUTE)}
          onClick={() => history.push(MED_ROUTE)}
        >
          <MedIcon selected={location.pathname.includes(MED_ROUTE)} />
          Med
        </MenuItem>
        <MenuItem
          color="var(--nutri)"
          selected={location.pathname.includes(NUTRI_ROUTE)}
          onClick={() => history.push(NUTRI_ROUTE)}
        >
          <NutriIcon selected={location.pathname.includes(NUTRI_ROUTE)} />
          Nutri
        </MenuItem>
        <MenuItem
          color="var(--psico)"
          selected={location.pathname.includes(PSICO_ROUTE)}
          onClick={() => history.push(PSICO_ROUTE)}
        >
          <PsicoIcon selected={location.pathname.includes(PSICO_ROUTE)} />
          Psico
        </MenuItem>
        <MenuItem
          color="var(--orange)"
          selected={location.pathname.includes(TRAINING_ROUTE)}
          onClick={() => history.push(TRAINING_ROUTE)}
        >
          <TrainingIcon selected={location.pathname.includes(TRAINING_ROUTE)} />
          Training
        </MenuItem>
        <MenuItem
          style={{ marginRight: 0 }}
          color="var(--orange-dark)"
          selected={location.pathname.includes(PARQ_ROUTE)}
          onClick={() => history.push(PARQ_ROUTE)}
        >
          <PsicoIcon selected={location.pathname.includes(PARQ_ROUTE)} />
          ParQ
        </MenuItem>
      </NavigationContainer>
      <div
        style={{
          cursor: "pointer",
          visibility: !location.pathname.includes(PARQ_ROUTE)
            ? "visible"
            : "hidden",
        }}
        onClick={() => setEncontrosToggled(!encontrosToggled)}
      >
        <CurrentEncontroContainer>
          <Encontro
            selected
            style={{ pointerEvents: "none", backgroundColor: getColor() }}
          >
            {selectedIndexAnamnesis + 1}
          </Encontro>
          <Arrow toggled={encontrosToggled} />
        </CurrentEncontroContainer>
        <EncontroText>Encontro</EncontroText>
      </div>
    </HeaderContainer>
  );

  const renderEncontros = () => (
    <EncontrosContainer toggled={encontrosToggled}>
      <EncontroText style={{ position: "absolute", top: 11 }}>
        Selecione o Encontro
      </EncontroText>
      {anamnesis.map((a, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <EncontroLine
              disabled={(a || {}).disabled}
              style={{
                ...(!(a || {}).disabled ? { backgroundColor: getColor() } : {}),
              }}
            />
          )}
          <Encontro
            selected={index === selectedIndexAnamnesis}
            disabled={(a || {}).disabled}
            onClick={() => {
              setSelectedIndexAnamnesis(index);
            }}
            style={{
              ...(!(a || {}).disabled ? { backgroundColor: getColor() } : {}),
            }}
          >
            {index + 1}
          </Encontro>
        </React.Fragment>
      ))}
    </EncontrosContainer>
  );

  return (
    <SystemContext.Provider
      value={{
        challenges: challenges.filter((c) => c.type !== "step"),
        stepChallenges: challenges.filter((c) => c.type === "step"),
      }}
    >
      <ClientContext.Provider
        value={{
          client,
          acceptedChallenges,
          saveAnamnesis,
          anamnesisType,
          anamnesis,
          currentAnamnesis: currentAnamnesis || {},
          setAnamnesisType,
          goal,
          updateGoal,
          downloadReport,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Clients />
          <div style={{ flex: 1, overflowY: "scroll", height: "100vh" }}>
            {renderNavigation()}
            {!location.pathname.includes(PARQ_ROUTE) && renderEncontros()}
            {isEmpty(client) && (
              <div
                style={{
                  height: "68%",
                  width: "100%",
                  fontFamily: "var(--font-heavy)",
                  fontSize: 16,
                  color: "var(--text)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Selecione um paciente ao lado
              </div>
            )}
            <PageContainer>
              {!_.isEmpty(client) && (
                <Switch>
                  <Route
                    path={`${match.url}/${RESUME_ROUTE}`}
                    component={Developing}
                  />
                  <Route path={`${match.url}/${TECH_ROUTE}`} component={Tech} />
                  <Route
                    path={`${match.url}/${MED_ROUTE}`}
                    exact
                    component={Med}
                  />
                  <Route
                    path={`${match.url}/${MED_ROUTE}/exams`}
                    component={ExamRequest}
                  />
                  <Route
                    path={`${match.url}/${NUTRI_ROUTE}`}
                    component={Nutri}
                  />
                  <Route
                    path={`${match.url}/${TRAINING_ROUTE}`}
                    component={Training}
                  />
                  <Route
                    path={`${match.url}/${PSICO_ROUTE}`}
                    component={Psico}
                  />
                  <Route path={`${match.url}/${PARQ_ROUTE}`} component={ParQ} />
                </Switch>
              )}
            </PageContainer>
          </div>
        </div>
        {saving && (
          <div
            style={{
              position: "absolute",
              height: 60,
              width: 280,
              backgroundColor: "var(--purple-dark)",
              bottom: 10,
              right: 20,
              color: "white",
              fontSize: 24,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0.7,
              borderRadius: 15,
            }}
          >
            <Loading />
            Salvando
          </div>
        )}
        <NotificationContainer />
      </ClientContext.Provider>
    </SystemContext.Provider>
  );
};

export default Anamnesis;
