import { createGlobalStyle } from "styled-components";
import { generateMedia } from "styled-media-query";

import GraviolaSoftRegular from "./fonts/Graviola-Soft-Regular.otf";
import GraviolaSoftHeavy from "./fonts/Graviola-Soft-Heavy.otf";
import GraviolaSoftBold from "./fonts/Graviola-Soft-Bold.otf";

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Graviola Soft Medium';
        src: url(${GraviolaSoftRegular}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Graviola Soft Regular';
        src: url(${GraviolaSoftRegular}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Graviola Soft Heavy';
        src: url(${GraviolaSoftHeavy}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Graviola Soft Bold';
        src: url(${GraviolaSoftBold}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

  :root {
    --font-medium: 'Graviola Soft Medium';
    --font-regular: 'Graviola Soft Regular';
    --font-heavy: 'Graviola Soft Heavy';
    --font-bold: 'Graviola Soft Bold';

    --resume: #302056;
    --tech: #EA5448;
    --med: #e7aa22;
    --nutri: #624E92;
    --mais-vida: #BE4F18;
    --psico: #BE4F18;
    --training: #F57A3D;
    --parq: #be4f18;

    --black: #000000;
    --purple: #3d2e61;
    --purple-dark: #302056;
    --purple-light: #624E92;
    --pink: #e86e84;
    --orange: #F57A3D;
    --orange-dark: #be4f18;
    --success: #33b6ae;
    --success-light: #42C9C1;
    --text: #a5a5a5;
    --text-light: rgba(112,112,112, 0.25);
    --hover-background: #ECECEC;
    --card-background: #F6F6F6;
    --danger-form: #ff4548;
    --danger-form: #ff454844;
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;

  }

  * {
    font-family: 'Graviola Soft Regular', Roboto, Helvetica, Arial, sans-serif;
    border: none;
    background-color: transparent;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
    padding: 0;
    margin-bottom: 0;
  }

  body.fontLoaded {
    font-family: 'Graviola Soft Regular', Roboto, Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }

  .row {
    margin-bottom: 15px !important; 
  }
`;

export default GlobalStyle;

export const customMedia = generateMedia({
  desktop: "78em",
  tablet: "55em",
  mobile: "42em",
  two_grid_size: "68em",
  one_grid_size: "29em",
});
