import React from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Anamnesis from "../pages/Anamnesis";

const routes = [
  <Redirect exact from="/" to="/anamnesis/tech" />,
  <PrivateRoute path="/anamnesis" component={Anamnesis} />,
];

export default routes;
