import React, { InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";
// import classNames from "classnames";
import InputMask from "react-input-mask";
import "./styles.scoped.scss";

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
}

const formatChars = {
  n: "[0-1]",
  m: "[0-9]",
  e: "[0-3]",
  d: "[0-9]",
  z: "[1-2]",
  y: "[0-9]",
};

const TextInput: React.FC<NumberInputProps> = (props) => {
  const { label, name } = props;
  const { control } = useFormContext();

  return (
    <div className="inputContainer">
      <Controller
        render={({ field }) => (
          <>
            <label>{label}</label>
            <InputMask
              //@ts-ignore
              formatChars={formatChars}
              mask="ed/nm/zyyy"
              alwaysShowMask={false}
              maskChar={null}
              placeholder="DD/MM/YYYY"
              className="dateInput"
              {...field}
            >
              {(inputProps: any) => <input {...inputProps} />}
            </InputMask>
          </>
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default TextInput;
