import React from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import MaisVida from "../pages/MaisVida";
import MaisVidaMeeting from "../pages/MaisVida/Meeting";

const routes = [
  <Redirect exact from="/" to="/maisvida" />,
  <PrivateRoute path="/maisvida" exact component={MaisVida} />,
  <PrivateRoute path="/maisvida/meeting/:id?" component={MaisVidaMeeting} />,
];

export default routes;
