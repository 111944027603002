import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { Row, Col } from "reactstrap";
import { DateInput, NumberInput, TextInput } from "../../../components/Formik";
import { H1, LeftPanel, Loading } from "../../../components";
import { LoadingOrange } from "../../../components/Loading";
import { SearchInput } from "../styles";
import {
  Title,
  Subtitle,
  RemoveIcon,
  AddIcon,
  AddButton,
  ExerciseWrapper,
  ExerciseCover,
} from "./styles";
import { FooterContainer, SaveButton } from "../styles";
import {
  selectCurrentClient,
  selectToggled,
} from "../../../redux/ducks/Client/selectors";
import _ from "lodash";
import moment from "moment";
import TrainingService from "../../../services/TrainingService";
import ExerciseSVG from "../../../assets/images/exercise.svg";

const Exercise = ({ name, index, exercise, onClick, onRemove }) => (
  <div style={{ position: "relative" }}>
    {index >= 0 && <RemoveIcon onClick={onRemove} />}
    <ExerciseWrapper hover={index < 0} onClick={onClick}>
      <ExerciseCover src={_.get(exercise, ["image"]) || ExerciseSVG} />
      <div style={{ paddingLeft: 17, paddingTop: 10 }}>
        <Title style={{ fontSize: 16, marginBottom: 0 }}>
          {_.get(exercise, ["title"])}
        </Title>
        <Subtitle style={{ fontSize: 11, textTransform: "uppercase" }}>
          {_.get(exercise, ["category"])} - {_.get(exercise, ["time"])} MIN -{" "}
          {_.get(exercise, ["difficulty"])}
        </Subtitle>
      </div>
      {index >= 0 && (
        <div style={{ display: "flex" }}>
          <NumberInput
            name={`${name}.${index}.series`}
            label="Séries"
            format="## séries"
            compact
            style={{ width: "50%" }}
          />
          <NumberInput
            name={`${name}.${index}.repetitions`}
            label="Repetições"
            format="## repetições"
            compact
            style={{ width: "50%" }}
          />
        </div>
      )}
    </ExerciseWrapper>
  </div>
);

const AddExercises = ({ name, model, exercises, label, madeTraining }) => {
  const [showExercises, setShowExercises] = useState(null);
  const [searchText, setSearchText] = useState("");

  const getCategories = useCallback(() => {
    const categoriesNames = _.get(model, ["categoriesExercises"], []).map(
      (name) => name.split("-")[0]
    );

    var unique = categoriesNames.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    return unique.map((value) => ({
      name: value,
      times: categoriesNames.filter((name) => name === value).length,
    }));
  }, [model]);

  const getExercises = useCallback(() => {
    let result = exercises.filter((e) => e.category.startsWith(showExercises));
    if (_.isEmpty(searchText)) {
      return result;
    }

    return result.filter(
      (e) =>
        _.get(e, "title", "")
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        _.get(e, "category", "")
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        _.get(e, "description", "")
          .toUpperCase()
          .includes(searchText.toUpperCase())
    );
  }, [exercises, showExercises, searchText]);

  return (
    <FieldArray
      name={`${name}.${model._id}.exercises`}
      render={({ push, remove, form }) => (
        <>
          <div style={{ padding: 17 }}>
            <Row>
              <Col>
                <Title>{model.title}</Title>
                <Subtitle>{model.description}</Subtitle>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <AddButton
                  onClick={() => madeTraining(model._id)}
                  style={{ borderStyle: "none" }}
                >
                  Marcar como feito hoje
                </AddButton>
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {_.get(form, ["values", name, model._id, "exercises"], []).map(
                  (exercise, exerciseIndex) => (
                    <Exercise
                      key={exerciseIndex}
                      name={`${name}.${model._id}.exercises`}
                      index={exerciseIndex}
                      exercise={exercise}
                      onRemove={() => remove(exerciseIndex)}
                    />
                  )
                )}
              </Col>
            </Row>

            <Row>
              <Col style={{ display: "flex", flexWrap: "wrap" }}>
                {getCategories().map((category) => (
                  <AddButton
                    key={category.name}
                    style={{
                      minWidth: 30,
                      width: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 12,
                      height: 40,
                      marginRight: 20,
                      marginBottom: 10,
                      textTransform: "none",
                      color: "var(--text)",
                    }}
                    onClick={() => setShowExercises(category.name)}
                  >
                    <AddIcon style={{ height: 15 }} />
                    Adicione {category.times} exercício
                    {category.times > 1 ? "s" : ""} de {category.name}
                  </AddButton>
                ))}
              </Col>
            </Row>
          </div>
          <LeftPanel
            large
            open={!_.isEmpty(showExercises)}
            onClose={() => setShowExercises(null)}
          >
            <Row>
              <Col>
                <Title>Banco de exercícios</Title>
                <Subtitle>
                  Você está adicionando a{" "}
                  <strong>{_.get(model, ["title"])}</strong> - {showExercises}
                </Subtitle>
              </Col>
              <Col>
                <SearchInput
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Pesquise exercícios"
                />
              </Col>
            </Row>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {getExercises().map((exercise) => (
                <Exercise
                  key={exercise._id}
                  exercise={exercise}
                  name={name}
                  index={-1}
                  onClick={() => {
                    push(exercise);
                    setShowExercises(null);
                  }}
                />
              ))}
            </div>
          </LeftPanel>
        </>
      )}
    />
  );
};

function Training() {
  const currentClient = useSelector(selectCurrentClient);
  const clientToggled = useSelector(selectToggled);
  const [loadingTrainings, setLoadingTrainings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [data, setData] = useState({
    models: [],
    current: {},
    made: [],
  });

  const getData = useCallback(async () => {
    setLoadingTrainings(true);
    try {
      let response = await TrainingService.getAllExercises();
      let newExercises = _.get(response, ["data", "data", "items"]);
      setExercises(newExercises);

      response = await TrainingService.getAllModelTrainings();
      const models = _.get(response, ["data", "data", "items"]).reverse();

      response = await TrainingService.getAll(currentClient._id, models.length);

      let observations = "";
      let trainingsData = await _.get(response, ["data", "data", "items"]);
      const trainings = {};

      trainingsData = trainingsData.filter((e) => e.modelTraining).reverse();
      trainingsData.forEach((t) => {
        observations = t.observations;
        trainings[t.modelTraining._id] = t;
      });

      response = await TrainingService.getAllMadeTraining(currentClient._id);
      const made = await _.get(response, ["data", "data", "items"]);

      Object.keys(trainings).forEach((key) => {
        const exercisesMap = trainings[key].exercises.map((e) => {
          const exercise = _.first(
            newExercises.filter((ex) => ex._id === e.exercise)
          );

          return { ...exercise, ...e };
        });
        trainings[key].exercises = exercisesMap;
      });

      setData({
        ...data,
        models,
        current: { trainings, observations },
        made,
      });
    } catch (e) {
    }

    setLoadingTrainings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  const save = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { trainings, observations } = values;

        Object.keys(trainings).map(async (modelTraining) => {
          const training = trainings[modelTraining];
          const { exercises } = training;
          const exerciseData = exercises.map((e) => {
            const { series, repetitions, _id, exercise } = e;
            return { series, repetitions, exercise: _id || exercise };
          });

          const saveData = {
            client: currentClient._id,
            modelTraining,
            exercises: exerciseData,
            observations,
          };
          await TrainingService.insert(saveData);
        });
        getData();
      } catch (e) {}
      setLoading(false);
    },
    [currentClient._id, getData]
  );

  const made = useCallback(
    async (training) => {
      setLoading(true);
      try {
        const data = {
          client: currentClient._id,
          responsibleProfessional: "5e67b452d01e87c53f7ee97d",
          training,
          when: moment().format(),
          weekDay: moment()
            .locale("en-US")
            .format("dddd")
            .toLowerCase(),
        };
        await TrainingService.insertMadeTraining(data);
        getData();
      } catch (e) {}
      setLoading(false);
    },
    [currentClient, getData]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const renderResumo = () => (
    <Row>
      <Col>
        <DateInput name="consultationDate" label="Data do Encontro" />
      </Col>
      <Col />
    </Row>
  );

  const renderTraining = ({ values }) => (
    <>
      <Row>
        <Col>
          <H1 color="training">Haux Training</H1>
        </Col>
      </Row>
      <FieldArray
        name={"trainings"}
        render={({ push, remove, form }) => (
          <>
            {data.models.map((model, index) => (
              <div key={model._id} style={{ marginBottom: 70 }}>
                <AddExercises
                  model={model}
                  exercises={exercises}
                  values={values}
                  name="trainings"
                  index={index}
                  label="Treino 01 - Força"
                  madeTraining={made}
                />
              </div>
            ))}
          </>
        )}
      />
    </>
  );

  const renderHistorico = () => (
    <>
      <Row>
        <Col>
          <H1 color="training">Histórico</H1>
        </Col>
      </Row>
      {data.made.map((made) => (
        <Row style={{ paddingLeft: 17, paddingRight: 17 }}>
          <Col>
            <Title>
              {moment(made.when).format("DD/MM/YYYY dddd")} - {made.time} MIN
            </Title>
          </Col>
        </Row>
      ))}
    </>
  );

  const renderSave = (dirty) => (
    <FooterContainer visible={dirty} clientToggled={clientToggled}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  if (loadingTrainings) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingOrange style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        consultationDate: moment().format("DD/MM/YYYY"),
        ...data.current,
      }}
      enableReinitialize={true}
      onSubmit={(values) => {
        save(values);
      }}
      // validationSchema={MeetingSchema}
      render={({ values, dirty }) => {
        return (
          <Form>
            {renderResumo()}
            {renderTraining({ values })}

            <Row>
              <Col>
                <TextInput
                  name="observations"
                  label="Observações"
                  placeholder="Descreva aqui oberservações adicionais"
                />
              </Col>
            </Row>

            {renderHistorico()}
            {renderSave(dirty)}
          </Form>
        );
      }}
    />
  );
}

export default Training;
