import styled from "styled-components";
import ArrowDownSVG from "../../assets/icons/arrow-down.svg";

export const Container = styled.div`
  margin-bottom: 50px;
  overflow: hidden;
`;

export const Header = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 17px;
  border-radius: 8px;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--hover-background);
  }
`;

export const Content = styled.div`
  display: ${(props) => (props.collapsed ? "none" : "block")};
  /* max-height: ${(props) => (props.collapsed ? 0 : 40000)}px; */
  transition: all 0.3s ease;
  overflow: hidden;
`;

export const Title = styled.label`
  font-family: var(--font-heavy);
  font-size: 20px;
  text-align: left;
  color: var(--purple);
  margin-bottom: 0;
`;

export const ArrowDown = styled.img.attrs({
  src: ArrowDownSVG,
})`
  transition: all 0.3s ease;
  transform: ${({ collapsed }) =>
    collapsed ? "rotateZ(0)" : "rotateZ(180deg)"};
`;
