import React from "react";

interface ClientContextInterface {
  saveAnamnesis: Function;
  acceptedChallenges: string[];
  anamnesis: any[];
  setAnamnesisType: Function;
  currentAnamnesis: any;
  client: any;
  goal: any;
  updateGoal: Function;
  downloadReport: Function;
}

const ClientContext = React.createContext<ClientContextInterface>({
  saveAnamnesis: () => {},
  acceptedChallenges: [],
  anamnesis: [],
  setAnamnesisType: () => {},
  currentAnamnesis: {},
  client: {},
  goal: {},
  updateGoal: () => {},
  downloadReport: () => {},
});
export default ClientContext;
