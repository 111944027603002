import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import "moment/locale/pt-br";
import { LoadingPurple } from "../../components/Loading";
import {
  Container,
  Header,
  WelcomeTitle,
  NewButton,
  CalendarIcon,
  ItemsContainer,
  ItemsTitle,
  LeftIcon,
  RightIcon,
  DatesContainer,
  DateButton,
  ListContainer,
  ListItem,
} from "./styles";
import MoreLifeService from "../../services/MoreLifeService";
import MORE_LIFE_MEETINGS from "../../utils/moreLifeMeetings";

moment.locale("pt-BR");

function MaisVida() {
  const history = useHistory();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateDiff, setDateDiff] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData(selectedDate.format("DD/MM/YYYY"));
  }, [selectedDate]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const consultationDate = query.get("consultationDate");
    if (consultationDate) {
      setSelectedDate(moment(consultationDate, "DD/MM/YYYY"));
    }
  }, [location]);

  const goToNewMeeting = (id) => {
    history.push(
      "/maisvida/meeting" +
        (id ? `/${id}` : "") +
        `?consultationDate=${selectedDate.format("DD/MM/YYYY")}`
    );
  };

  const getDates = useCallback(() => {
    const currentDate = moment().add(dateDiff, "weeks");
    const firstDate = moment(currentDate).startOf("week");
    const lastDate = moment(currentDate).add(2, "weeks").endOf("week");

    var now = firstDate.clone(),
      dates = [];

    while (now.isSameOrBefore(lastDate)) {
      dates.push(moment(now));
      now.add(1, "days");
    }
    return dates;
  }, [dateDiff]);

  const getData = async (consultationDate) => {
    setLoading(true);
    const response = await MoreLifeService.getAll(consultationDate);
    const items = _.get(response, ["data", "data", "items"]);
    setData(items);
    setLoading(false);
  };

  const renderDates = () => (
    <DatesContainer>
      <LeftIcon onClick={() => setDateDiff(dateDiff - 1)} />

      {getDates().map((m) => (
        <DateButton
          key={m.valueOf()}
          selected={m.isSame(selectedDate, "day")}
          onClick={() => setSelectedDate(m)}
        >
          <strong>{m.format("DD")}</strong>
          <br />
          {m.format("MMM")}
        </DateButton>
      ))}
      <RightIcon onClick={() => setDateDiff(dateDiff + 1)} />
    </DatesContainer>
  );

  const renderList = () => (
    <>
      <ListContainer>
        {data.length === 0 && <p>Não foram encontrados encontros neste dia</p>}
        {data.map((item) => (
          <ListItem
            key={item._id}
            onClick={() => {
              goToNewMeeting(item._id);
            }}
          >
            <div className="hour">{item.hour}</div>
            <div className="info">
              <span>
                Mais Vida <strong>- {item.theme}</strong>
              </span>
              <br />
              SALA MULTISPACE
            </div>
            <div className="number">
              {("0" + (_.indexOf(MORE_LIFE_MEETINGS, item.theme) + 1)).slice(
                -2
              )}
            </div>
          </ListItem>
        ))}
      </ListContainer>
    </>
  );

  return (
    <Container>
      <Header>
        <WelcomeTitle>
          Bem-vindo, <strong>Psicóloga</strong>
        </WelcomeTitle>
        <NewButton onClick={() => goToNewMeeting()}>Novo encontro</NewButton>
      </Header>
      <ItemsContainer>
        <ItemsTitle>
          <CalendarIcon />
          Resumo do seu dia {selectedDate.format("DD [de] MMMM [de] YYYY")}
        </ItemsTitle>
        {renderDates()}
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadingPurple />
          </div>
        )}
        {!loading && renderList()}
      </ItemsContainer>
    </Container>
  );
}

export default MaisVida;
