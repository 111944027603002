import React, { useRef, InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";
// import classNames from "classnames";
import ContentEditable from "react-contenteditable";
import placeCaretAtEnd from "./placeCaretAtAnd";
import "./styles.scoped.scss";

interface Option {
  label: string;
  value: string | number;
}

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  options?: Option[];
}

const TextInput: React.FC<NumberInputProps> = (props) => {
  const ref = useRef<HTMLElement>();
  const { label, name } = props;
  const { control } = useFormContext();

  return (
    <div
      className="inputContainer"
      onMouseEnter={() => {
        placeCaretAtEnd(ref.current);
      }}
    >
      <Controller
        render={({ field }) => (
          <>
            <label>{label}</label>
            <ContentEditable
              // @ts-ignore
              innerRef={ref}
              html={field.value || ""}
              {...field}
              disabled={false}
              data-placeholder="Digite aqui..."
            />
          </>
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default TextInput;
