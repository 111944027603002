import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col } from "reactstrap";
import RadioGroup from "../../../components/Form/RadioGroup";
import CheckGroup from "../../../components/Form/CheckGroup";
import TextInput from "../../../components/Form/TextInput";
import "./styles.scoped.scss";

function Anamnesis() {
  const methods = useForm();
  const { reset } = methods;

  useEffect(() => {
    // setTimeout(() => {
    //   reset({
    //     name: "teste_1",
    //     money: 3444.36,
    //     therapyReasons: "hahaha ble",
    //   });
    // }, 2000);
  }, [reset]);

  const onSubmit = (data: any) => {};

  const renderResume = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Anamnese psicologia</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="hadTherapy"
            label="Já fez/faz terapia?"
            options={["Já fez", "Nunca", "Faz atualmente"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput name="therapyReasons" label="Se sim, qual o motivo?" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="gains"
            label="Como foi/está sendo o processo? Ganhos percebidos?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="mentalHealthDiagnosis"
            label="Já recebeu algum diagnóstico de saúde mental? "
            options={["Sim", "Não"]}
          />
        </Col>
        <Col>
          <TextInput name="gains" label="Qual?" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="treatments"
            label="Faz algum tratamento nessa área?"
          />
        </Col>
      </Row>
    </>
  );

  const renderHistoricoFamiliar = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Configuração e histórico familiar</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="relativesYouLive"
            label="Com quem mora, nome e grau de parentesco"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="relationship"
            label="Como é a relação com os familiares (próxima, distante, conflituosa"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="weightGainHistoric"
            label="Histórico de ganho de peso, ansiedade, depressão e outras questões psicológicas na família"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="eatingHabits"
            label="Como são os hábitos familiares de alimentação"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="childEatingHabits"
            label="Como eram os hábitos familiares relacionados à alimentação na família de origem, quando você era criança"
          />
        </Col>
        <Col>
          <TextInput name="teenageEatingHabits" label="E na adolescência?" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="traumas"
            label="Você vivenciou traumas e/ou perdas e/ou outros eventos significativos que considere representativos ao longo da vida (ex. algum tipo de violência, acidente, separações, morte..."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Você passou por situações de bulling quando criança e/ou adolescente?"
            name="bulling"
            options={["Sim", "Não"]}
          />
        </Col>
        <Col>
          <TextInput
            name="bullingAffects"
            label="Como sente que isso o afeta hoje?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="significantPeople"
            label="Quem são as pessoas mais significativas na sua vida?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Como avalia sua rede de apoio social?"
            name="socialSupportNetwork"
            direction={"column"}
            options={[
              "Possuo um bom número de amigos e/ou pessoas confiáveis com quem posso contar",
              "Possuo poucos amigos e/ou pessoas confiáveis com quem posso contar",
              "Não possuo amigos e/ou pessoas confiáveis com quem posso contar",
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Algum membro da sua família já teve uma tentativa de suicídio?"
            name="familySuicideAttempt"
            options={["Sim", "Não"]}
          />
        </Col>
        <Col>
          <TextInput
            name="kinshipFamilySuicideAttempt"
            label="Em caso afirmativo, qual seu grau de parentesco com essa pessoa?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Algum membro da sua família já morreu por suicídio?"
            name="familySuicide"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Você já pensou em suicídio?"
            name="suicideAttempt"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="numberSuicideAttempts"
            label="Em caso afirmativo, quantas vezes?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="suicideAttemptsDescriptions"
            label="Descreva as tentativas de suicícios (Data aproximada, o que exatamente fez, se foi hospitalizado ou não)"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="leisureActivities"
            label="Quais são suas atividades de lazer e principais interesses (sociais, intelectuais, como passa o tempo livre)? "
          />
        </Col>
      </Row>
    </>
  );

  const renderRastreioDepressao = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Rastreio depressão</h1>
          <h3 className="psico">Considerando as duas últimas semanas</h3>
        </Col>
      </Row>
      {[
        {
          question: "Sente-se triste, vazio, desanimado ou sem esperança?",
          id: "feelSad",
        },
        {
          question:
            "Acentuada diminuição do interesse ou prazer em todas ou quase todas as atividades na maior parte do dia, quase todos os dias?  ",
          id: "lowInterest",
        },
        {
          question:
            "Teve perda ou ganho significativo de peso sem estar fazendo dieta ou redução ou aumento do apetite?",
          id: "weightLossOrGain",
        },
        {
          question: "Insônia ou hipersonia?",
          id: "insomniaOrHypersomnia",
        },
        {
          question:
            "Agitação/ inquietação ou lentidão percebida por outras pessoas em suas atividades?",
          id: "agitation",
        },
        {
          question: "Fadiga ou perda de energia quase todos os dias?",
          id: "fadigue",
        },
        {
          question: "Sentimentos de inutilidade ou culpa excessiva?",
          id: "feelingsOfWorthlessness",
        },
        {
          question:
            "Capacidade diminuída para pensar ou se concentrar, ou indecisão?",
          id: "decreasedConcentration",
        },
        {
          question:
            "Pensamentos recorrentes de morte (não somente medo de morrer), ideias sobre morrer, tentativa ou plano específico para cometer suicídio?",
          id: "deathThoughts",
        },
        {
          question:
            "Esses sintomas têm lhe causado sofrimento significativo ou prejuízo no funcionamento social, profissional ou em outras áreas importantes da sua vida?",
          id: "sufferingSymptoms",
        },
      ].map(({ question, id }) => (
        <Row key={id}>
          <Col>
            <RadioGroup label={question} name={id} options={["Sim", "Não"]} />
          </Col>
        </Row>
      ))}
    </>
  );

  const renderRastreioAnsiedade = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Rastreio depressão</h1>
          <h3 className="psico">
            Considerando os últimos sete dias, o quanto se sentiu incomodado
            pelos seguintes sintomas
          </h3>
        </Col>
      </Row>
      {[
        { question: "Dormência ou formigamento", id: "numbnessOrTingling" },
        {
          question: "Sensação de calor ou suor (não devido ao calor)",
          id: "heatOrSweat",
        },
        { question: "Incapacidade de relaxar", id: "inabilityToRelax" },
        { question: "Sensação de desmaio", id: "feelingFaint" },
        { question: "Atordoado ou tonto", id: "stunnedOrDizzy" },
        { question: "Palpitação ou aceleração do coração", id: "palpitation" },
        { question: "Sem equilíbrio", id: "outOfBalance" },
        { question: "Assustado ou aterrorizado", id: "scaredOrTerrified" },
        { question: "Nervoso", id: "nervous" },
        { question: "Sensação de sufocação", id: "feelingOfSuffocation" },
        { question: "Tremores (pernas, mãos)", id: "tremors" },
        { question: "Medo de perder o controle", id: "fearOfLosingControl" },
        { question: "Dificuldade de respirar", id: "difficultyBreathing" },
        {
          question: "Indigestão ou desconforto no abdômen",
          id: "abdomenDiscomfort",
        },
        { question: "Rosto afogueado", id: "flushedFace" },
      ].map(({ question, id }) => (
        <Row key={id}>
          <Col>
            <RadioGroup
              label={question}
              name={id}
              options={[
                "Absolutamente, não",
                "Levemente, não me incomodou muito",
                "Moderadamente, foi muito desagradável mas pude suportar",
                "Gravemente, dificilmente pude suportar",
              ]}
            />
          </Col>
        </Row>
      ))}
    </>
  );

  const renderRastreioCompulsaoAlimentar = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Rastreio de Compulsão Alimentar</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Percebe dificuldades para controlar sua ingesta de alimentos?"
            name="difficultToControlFood"
            options={["Sim", "Não"]}
          />
        </Col>
        <Col>
          <RadioGroup
            label="Tem episódios de começar a comer e ter dificuldade para parar?"
            name="difficultToStopEaten"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="psico">
            Vou lhe apresentar grupos de afirmações. Indique aquela que melhor
            descreve o modo como você se sente em relação aos problemas que tem
            para controlar seu comportamento alimentar.
          </h3>
        </Col>
      </Row>
      {[
        {
          id: "compulsion1",
          options: [
            "Eu não me sinto constrangido(a) com o meu peso ou o tamanho do meu corpo quando estou com outras pessoas.",
            "Eu me sinto preocupado(a) em como pareço para os outros, mas isto, normalmente, não me faz sentir desapontado(a) comigo mesmo(a).",
            "Eu fico mesmo constrangido(a) com a minha aparência e o meu peso, o que me faz sentir desapontado(a) comigo mesmo(a).",
            "Eu me sinto muito constrangido(a) com o meu peso e, frequentemente, sinto muita vergonha e desprezo por mim mesmo(a). Tento evitar contatos sociais por causa desse constrangimento.",
          ],
        },
        {
          id: "compulsion2",
          options: [
            "Eu não tenho nenhuma dificuldade para comer devagar, de maneira apropriada.",
            "Embora pareça que eu devore os alimentos, não acabo me sentindo empanturrado(a) por comer demais.",
            "Às vezes tendo a comer rapidamente, sentindo-me então desconfortavelmente cheio(a) depois.",
            "Eu tenho o hábito de engolir minha comida sem realmente mastigá-la. Quando isto acontece, em geral me sinto desconfortavelmente empanturrado(a) por ter comido demais.",
          ],
        },
        {
          id: "compulsion3",
          options: [
            "Eu me sinto capaz de controlar meus impulsos para comer, quando eu quero.",
            "Eu sinto que tenho falhado em controlar meu comportamento alimentar mais do que a média das pessoas.",
            "Eu me sinto totalmente incapaz de controlar meus impulsos para comer.",
            "Por me sentir tão incapaz de controlar meu comportamento alimentar, entro em desespero tentando manter o controle.",
          ],
        },
        {
          id: "compulsion4",
          options: [
            "Eu não tenho o hábito de comer quando estou chateado(a).",
            "Às vezes eu como quando estou chateado(a) mas, frequentemente, sou capaz de me ocupar e afastar minha mente da comida.",
            "Eu tenho o hábito regular de comer quando estou chateado(a) mas, de vez em quando, posso usar alguma outra atividade para afastar minha mente da comida.",
            "Eu tenho o forte hábito de comer quando estou chateado(a). Nada parece me ajudar a parar com esse hábito.",
          ],
        },
        {
          id: "compulsion5",
          options: [
            "Eu normalmente sei se estou ou não fisicamente com fome. Eu como a porção certa de comida para me satisfazer.",
            "De vez em quando eu me sinto em dúvida para saber se estou ou não fisicamente com fome. Nessas ocasiões é difícil saber quanto eu deveria comer para me satisfazer.",
            "Mesmo que se eu pudesse saber quantas calorias eu deveria ingerir, não teria ideia alguma de qual seria a quantidade “normal” de comida para mim.",
          ],
        },
        {
          id: "compulsion6",
          options: [
            "Normalmente quando como alguma coisa é porque estou fisicamente com fome.",
            "De vez em quando como alguma coisa por impulso, mesmo quando não estou realmente com fome.",
            "Eu tenho o hábito regular de comer alimentos que realmente não aprecio para satisfazer uma sensação de fome, mesmo que fisicamente eu não necessite de comida.",
            "Mesmo que não esteja fisicamente com fome, tenho uma sensação de fome em minha boca que somente parece ser satisfeita quando eu como um alimento, tipo um sanduíche, que enche a minha boca. Às vezes, quando eu como o alimento para satisfazer minha “fome na boca”, em seguida eu o cuspo, assim não ganharei peso.",
          ],
        },
        {
          id: "compulsion7",
          options: [
            "Eu não sinto qualquer culpa ou ódio de mim mesmo(a) depois de comer demais.",
            "De vez em quando sinto culpa ou ódio de mim mesmo(a) depois de comer demais.",
            "Quase o tempo todo sinto muita culpa ou ódio de mim mesmo(a) depois de comer demais.",
          ],
        },
        {
          id: "compulsion8",
          options: [
            "Em geral, minha ingesta calórica não sobe a níveis muito altos, nem desce a níveis muito baixos.",
            "Às vezes, depois de comer demais, tento reduzir minha ingesta calórica para quase nada, para compensar o excesso de calorias que ingeri.",
            "Eu tenho o hábito regular de comer demais durante a noite. Parece que a minha rotina não é estar com fome de manhã, mas comer demais à noite.",
            "Na minha vida adulta tenho tido períodos, que duram semanas, nos quais praticamente me mato de fome. Isto se segue a períodos em que como demais. Parece que vivo uma vida de “festa” ou de “morrer de fome”.",
          ],
        },
        {
          id: "compulsion9",
          options: [
            "Normalmente eu sou capaz de parar de comer quando quero. Eu sei quando “já chega”.",
            "De vez em quando, eu tenho uma compulsão para comer que parece que não posso controlar.",
            "Frequentemente tenho fortes impulsos para comer que parece que não sou capaz de controlar, mas, em outras ocasiões, posso controlar meus impulsos para comer.",
            "Eu tenho um problema para parar de comer uma vez que tenha começado e me sinto incapaz de controlar impulsos para comer, a ponto de ter medo de não ser capaz de parar.",
            "Por eu ter o problema de não ser capaz de parar de comer quando quero, às vezes tenho que provocar o vômito, usar laxativos e/ou diuréticos para aliviar minha sensação de empanturramento.",
          ],
        },
        {
          id: "compulsion10",
          options: [
            "Parece que eu como tanto quando estou com os outros (reuniões familiares, sociais), como quando estou sozinho(a).",
            "Às vezes, quando eu estou com outras pessoas, não como tanto quanto eu quero comer porque me sinto constrangido(a) com o meu comportamento alimentar.",
            "Frequentemente eu como só uma pequena quantidade de comida quando outros estão presentes, pois me sinto muito envergonhado com o meu comportamento alimentar.",
            "Eu me sinto tão envergonhado(a) por comer demais que escolho horas para comer demais quando sei que ninguém me verá. Eu me sinto como uma pessoa que se esconde para comer.",
          ],
        },
        {
          id: "compulsion11",
          options: [
            "Eu não penso muito sobre comida.",
            "Eu tenho fortes desejos por comida, mas eles só duram curtos períodos de tempo.",
            "Há dias em que parece que eu não posso pensar em mais nada a não ser comida.",
            "Na maioria dos dias, meus pensamentos parecem estar “preocupados” com comida. Sinto como se eu vivesse para Comer.",
          ],
        },
      ].map(({ options, id }, index) => (
        <Row key={id}>
          <Col>
            <RadioGroup
              label={`#${index + 1}`}
              name={`bingeEating.${index}`}
              options={options}
            />
          </Col>
        </Row>
      ))}
    </>
  );

  const renderHint = () => (
    <>
      <h6 className="psico">0 - De modo algum pronto para mudar;</h6>
      <h6 className="psico">1-3 - Pensando em mudar;</h6>
      <h6 className="psico">4-6 - Preparando-me para mudar;</h6>
      <h6 className="psico">
        7-10 - Trabalhando ativamente na mudança ou mantendo a mudança;
      </h6>
    </>
  );

  const renderMudancas = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">Réguas de prontidão para mudança</h1>
          <h3 className="psico">Quanto à atividade física</h3>
        </Col>
      </Row>
      {[
        {
          question:
            "O quanto você se considera pronto neste momento para realizar pelo menos 30 minutos de atividade física (Definida como qualquer movimento corporal produzido em consequência da contração muscular que resulte em gasto calórico. Ex: subir escada, limpar a casa, etc) leve a moderada, de forma contínua ou acumulada na maioria dos dias da semana, incluindo mudanças no seu cotidiano?",
          id: "change1",
        },
        {
          question:
            "O quanto você se considera pronto neste momento para fazer exercício aeróbico (Tipo de atividade física com intencionalidade de movimento, com atividades planejadas, repetitivas e estruturadas, realizadas para a manutenção da saúde ou a melhora do condicionamento físico), 3 a 5 vezes por semana, com duração de 30 a 60 minutos contínuos?",
          id: "change2",
        },
      ].map(({ question, id }) => (
        <Row key={id}>
          <Col>
            <RadioGroup
              label={question}
              name={id}
              options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
              compact={true}
            />
            {renderHint()}
          </Col>
        </Row>
      ))}

      <Row>
        <Col>
          <h3 className="psico">Quanto à alimentação</h3>
        </Col>
      </Row>
      {[
        {
          question:
            "O quanto você se considera pronto neste momento para reduzir a ingestão de frituras, empanados, embutidos, folhados e produtos industrializados ricos em gorduras, como balas e sorvetes?",
          id: "change3",
        },
        {
          question:
            "O quanto você se considera pronto neste momento para mudar o tipo de gordura consumida (de manteigas, banha, margarina – para óleo vegetal de soja, canola, girassol, azeite de oliva)?",
          id: "change4",
        },
        {
          question:
            "O quanto você se considera pronto neste momento para aumentar a ingestão de frutas, verduras e legumes?",
          id: "change5",
        },
        {
          question:
            "O quanto você se considera pronto neste momento para reduzir a ingestão de açúcar (açúcar branco, refrigerantes, doces em geral)?",
          id: "change6",
        },
        {
          question:
            "O quanto você se considera pronto neste momento para reduzir a ingestão de sal adicionado na comida (caldo em tabletes, sopas de envelope, embutidos, alimentos em conserva)?",
          id: "change7",
        },
      ].map(({ question, id }) => (
        <Row key={id}>
          <Col>
            <RadioGroup
              label={question}
              name={id}
              options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
              compact={true}
            />
            {renderHint()}
          </Col>
        </Row>
      ))}
    </>
  );

  const renderPercepcoes = () => (
    <>
      <Row>
        <Col>
          <h1 className="psico">
            Para preenchimento do psicólogo a apartir de suas percepções
          </h1>
          <h3 className="psico">Conciência</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Estado de consciência"
            name="stateOfConsciousness"
            options={["Satisfatório", "Insatisfatório"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Confusão Mental"
            name="mentalConfusion"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Ideias delirantes"
            name="delusionalIdeas"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Fluência Verbal (Início, meio e fim)"
            name="verbalFluency"
            options={["Satisfatório", "Regular", "Insatisfatório"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="psico">Pensamento e Linguagem</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Curso do pensamento"
            name="courseOfThought"
            options={["Normal", "Confuso", "Com fuga de ideias"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Distúrbios aparentes da fala/linguagem?"
            name="languageDisorders"
            options={["Sim", "Não"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Expressão verbal"
            name="verbalExpression"
            options={["Satisfatória", "Regular", "Insatisfatória"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="psico">Motricidade</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Gesticulações"
            name="gestures"
            options={["Em excesso", "Normal", "Não há"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Tremores"
            name="tremors"
            options={["Em excesso", "Não há"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            label="Destreza"
            name="dexterity"
            options={[
              "Satisfatório",
              "Regular",
              "Insatisfatório",
              "Não avaliado",
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="psico">
            Observação de Estados Emocionais/Características
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            label=""
            name="dexterity"
            options={[
              "Apatia",
              "Moralidade",
              "Irritabilidade",
              "Religiosidade",
              "Impulsividade",
              "Depressão",
              "Afetividade",
              "Agressividade",
              "Autoestima",
              "Ansiedade",
              "Euforia",
            ]}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {renderResume()}
        {renderHistoricoFamiliar()}
        {renderRastreioDepressao()}
        {renderRastreioAnsiedade()}
        {renderRastreioCompulsaoAlimentar()}
        {renderMudancas()}
        {renderPercepcoes()}
      </form>
    </FormProvider>
  );
}

export default Anamnesis;
