import axios from "../middlewares/axios";

class PDFService {
  static generateReport(clientId, techAnamnesisId) {
    const url = `/pdf/report?client=${clientId}&techAnamnesis=${techAnamnesisId}`;
    return axios.get(url, { responseType: "blob" });
  }
}

export default PDFService;
