import React, { InputHTMLAttributes, useCallback } from "react";
import classNames from "classnames";
import { useFormContext, Controller } from "react-hook-form";
import "./styles.scoped.scss";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
}

const Select: React.FC<RadioProps> = (props) => {
  const { label, name, options } = props;
  const { control } = useFormContext();

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  return (
    <Controller
      render={({ field }) => (
        <div
          className={classNames({
            container: true,
            compact: props.compact,
          })}
        >
          <label>{label}</label>
          <select {...field}>
            {getOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}
      control={control}
      name={name}
    />
  );
};

export default Select;
