import styled from "styled-components";
import MaisVidaSVG from "../../assets/icons/mais-vida-highlight.svg";
import CalendarSVG from "../../assets/icons/calendar.svg";
import LeftSVG from "../../assets/icons/left.svg";

export const Container = styled.div`
  padding: 50px;
  padding-bottom: 150px;
`;

export const MaisVidaIcon = styled.img.attrs({
  src: MaisVidaSVG,
})`
  width: 56px;
  height: 56px;
`;

export const Title = styled.div`
  font-family: var(--font-heavy);
  font-size: 22px;
  color: var(--mais-vida);
  text-transform: uppercase;
  margin-left: 16px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
`;

export const WelcomeTitle = styled.h1`
  font-family: var(--font-heavy);
  font-size: 24px;
  color: var(--purple);

  strong {
    font-size: 32px;
    font-family: var(--font-heavy);
  }
`;

export const NewButton = styled.button.attrs({
  type: "button",
})`
  min-height: 34px;
  border-radius: 8px;
  background-color: var(--orange-dark);
  border: none;
  padding: 14px 24px;
  font-family: var(--font-heavy);
  font-size: 13px;
  color: var(--white);
  text-transform: uppercase;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--orange);
  }
`;

export const CalendarIcon = styled.img.attrs({
  src: CalendarSVG,
})`
  margin: -20px;
  margin-right: 0;
`;

export const ItemsContainer = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  padding: 33px 0px;
`;

export const ItemsTitle = styled.div`
  padding: 0 22px;
  font-family: var(--font-heavy);
  font-size: 19px;
  color: var(--purple);
`;

export const LeftIcon = styled.img.attrs({
  src: LeftSVG,
})`
  cursor: pointer;
`;

export const RightIcon = styled(LeftIcon)`
  transform: rotateZ(180deg);
`;

export const DatesContainer = styled.div`
  margin: 27px 0;
  padding: 0 22px;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;
`;

export const DateButton = styled.button.attrs({
  type: "button",
})`
  height: 51px;
  width: 49px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--text-light);
  font-family: var(--font-regular);
  font-size: 10px;
  color: ${({ selected }) => (selected ? "#fff" : "var(--text)")};
  background-color: ${({ selected }) =>
    selected ? "var(--purple)" : "transparent"};
  text-transform: uppercase;
  transition: all 0.3s ease;

  strong {
    font-family: var(--font-heavy);
    font-size: 20px;
    line-height: initial;
  }

  :hover {
    background-color: ${({ selected }) =>
      selected ? "var(--purple-dark)" : "var(--hover-background)"};
  }
`;

export const ListContainer = styled.div`
  color: var(--purple-light);

  p {
    flex: 1;
    text-align: center;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 22px;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--hover-background);
  }

  .hour {
    font-family: var(--font-bold);
    font-size: 14px;
    color: var(--text);
  }

  .info {
    flex: 1;
    font-family: var(--font-bold);
    font-size: 12px;
    color: var(--text);
    padding: 17px 29px;
    line-height: initial;

    span {
      font-family: var(--font-bold);
      font-size: 18px;
      color: var(--orange-dark);
      line-height: initial;

      strong {
        color: var(--purple);
      }
    }
  }

  .number {
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-heavy);
    font-size: 16px;
    border-radius: 8px;
    background-color: var(--orange-dark);
    color: #fff;
  }
`;

export const Encontro = styled.button`
  font-family: var(--font-heavy);
  color: white;
  background-color: var(--pink);
  height: ${({ selected }) => (selected ? 50 : 38)}px;
  width: ${({ selected }) => (selected ? 50 : 38)}px;
  border-radius: ${({ selected }) => (selected ? 8 : 19)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease, padding 0.8s linear;

  :disabled {
    background-color: var(--text-light);
  }
`;

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 60px;
  right: 0;
  height: 69px;
  background-color: var(--card-background);
  font-family: var(--font-heavy);
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 88px;
  transition: all 0.3s ease, padding 0.5s linear;
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(69px)"};
`;

export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 15px;
  background-color: var(--success);
  color: #fff;
  padding: 5px 24px;
  min-height: 50px;
  border-radius: 8px;
  text-transform: uppercase;

  :hover {
    background-color: var(--success-light);
  }
`;
