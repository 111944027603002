import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Content, Header, Title, ArrowDown } from "./styles";

function Collapse({ title, children }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Container>
      <Header onClick={() => setCollapsed(!collapsed)}>
        <Title>{title}</Title>
        <ArrowDown collapsed={collapsed} />
      </Header>
      <Content collapsed={collapsed}>
        <div>{children}</div>
      </Content>
    </Container>
  );
}

PropTypes.defaultProps = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Collapse;
