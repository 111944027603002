// @ts-nocheck
import React, { InputHTMLAttributes, useCallback } from "react";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
import "./styles.scoped.scss";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface CheckProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
  renderOther?: React.ReactNode;
}

const CheckGroup: React.FC<CheckProps> = (props) => {
  const { label, name, options } = props;
  const { control, setValue } = useFormContext();

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  const isOther = (value) => {
    return (
      value &&
      (value.includes("Outro") ||
        value.includes("Outros") ||
        value.includes("Outra") ||
        value.includes("Outras"))
    );
  };

  return (
    <Controller
      render={({ field }) => (
        <>
          <div className="container">
            <label>{label}</label>
            <div className={`items ${props.direction}`}>
              {getOptions().map((option) => (
                <div
                  key={option.value}
                  className={classNames({
                    option: true,
                    checked: (field.value || []).includes(option.value),
                    compact: props.compact,
                  })}
                  onClick={() => {
                    const values = field.value || [];
                    if (values.includes(option.value)) {
                      setValue(
                        name,
                        values.filter((v) => v !== option.value)
                      );
                    } else {
                      setValue(name, [...values, option.value]);
                    }
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
          {props.renderOther && (
            <div
              className="otherContainer"
              style={{ display: isOther(field.value) ? "block" : "none" }}
            >
              {props.renderOther}
            </div>
          )}
        </>
      )}
      control={control}
      name={name}
    />
  );
};

export default CheckGroup;
