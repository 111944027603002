import axios from "../middlewares/axios";

class FileService {
  static uploadImage(form) {
    const url = "/file/uploadImage";
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, form, config);
  }
}

export default FileService;
