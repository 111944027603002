import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import { H1 } from "../../components";
import { AddClient } from "../Clients";
import { Loading } from "../../components";
import {
  Select,
  NumberInput,
  DateInput,
  TextInput,
} from "../../components/Formik";
import {
  Container,
  Header,
  MaisVidaIcon,
  Title,
  Encontro,
  FooterContainer,
  SaveButton,
} from "./styles";
import MeetingSchema from "./Schema";
import MORE_LIFE_MEETINGS from "../../utils/moreLifeMeetings";
import _ from "lodash";

import MoreLifeService from "../../services/MoreLifeService";
import moment from "moment";

const EncontroItem = ({ name, index }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    {index >= 0 && (
      <Encontro
        selected
        style={{
          height: 37,
          width: 37,
          marginRight: 17,
          backgroundColor: "var(--mais-vida)",
        }}
      >
        {("0" + (index + 1)).slice(-2)}
      </Encontro>
    )}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <H1
        color="mais-vida"
        showLine={false}
        style={{ padding: 0, margin: 0, fontSize: 16 }}
      >
        {name}
      </H1>
      {index >= 0 && (
        <span style={{ fontSize: 16, color: "var(--text)" }}>
          ENCONTRO #{("0" + (index + 1)).slice(-2)}
        </span>
      )}
    </div>
  </div>
);

function MaisVidaForm() {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getData = async (meetingId) => {
    if (!meetingId) {
      const query = new URLSearchParams(location.search);
      let consultationDate = query.get("consultationDate")
        ? query.get("consultationDate")
        : moment().format("DD/MM/YYYY");
      setData({ consultationDate });
    } else {
      const response = await MoreLifeService.get(meetingId);
      const data = _.get(response, ["data", "data"]);
      setData(data);
    }
  };

  const save = async (formData) => {
    setLoading(true);
    try {
      await MoreLifeService.save({
        _id: id,
        responsibleProfessional: "5f523484e912ff4a31221602",
        ...formData,
      });

      history.push(`/maisvida?consultationDate=${formData.consultationDate}`);
    } catch (e) {}

    setLoading(false);
  };

  const renderResumo = () => (
    <>
      <Row>
        <Col>
          <DateInput name="consultationDate" label="Data do Encontro" />
        </Col>
        <Col>
          <NumberInput name="hour" label="Horário" format="##H##" />
        </Col>
      </Row>
      <Row>
        <Col>
          <AddClient name="clients" />
        </Col>
      </Row>
      <Row>
        <Col>
          <H1 color={"mais-vida"}>Resumo do Encontro</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Tema do encontro"
            name="theme"
            options={MORE_LIFE_MEETINGS.map((m) => ({
              label: m,
              value: m,
            }))}
            renderItem={(value) => (
              <EncontroItem
                name={value}
                index={_.indexOf(MORE_LIFE_MEETINGS, value)}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="negativePoints"
            label="Pontos negativos"
            placeholder="Comente os pontos negativos do encontro"
          />
        </Col>
        <Col>
          <TextInput
            name="positivePoints"
            label="Pontos positivos"
            placeholder="Comente os pontos positivos do encontro"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="difficulties"
            label="Dificuldades"
            placeholder="Relate as principais dificuldades encontradas no encontro"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="reviews"
            label="Críticas"
            placeholder="Quais as críticas que foram colocadas?"
          />
        </Col>
        <Col>
          <TextInput
            name="suggestions"
            label="Sugestões"
            placeholder="Quais as sugestões que foram colocadas?"
          />
        </Col>
      </Row>
    </>
  );

  const renderSave = (dirty) => (
    <FooterContainer visible={dirty}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  return (
    <Container>
      <Header style={{ paddingLeft: 17, justifyContent: "flex-start" }}>
        <MaisVidaIcon />
        <Title>{id ? "Edição do" : "Novo"} encontro mais vida</Title>
      </Header>
      <Formik
        initialValues={data}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          save(values);
        }}
        validationSchema={MeetingSchema}
        render={({ dirty }) => {
          return (
            <Form>
              {renderResumo()}
              {renderSave(dirty)}
            </Form>
          );
        }}
      />
    </Container>
  );
}

export default MaisVidaForm;
