import React from "react";

interface Challenge {
  _id: string;
  name: string;
  translation?: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  tip: string[];
  isDeleted: boolean;
}

interface SystemContextInterface {
  challenges: Challenge[];
  stepChallenges: Challenge[];
}

const SystemContext = React.createContext<SystemContextInterface>({
  challenges: [],
  stepChallenges: [],
});
export default SystemContext;
