import React from "react";
import { Container, HauxIcon, ProfileIcon } from "./styles";

function Menu({ onUserClick }) {
  return (
    <Container>
      <HauxIcon />
      <ProfileIcon onClick={onUserClick} />
    </Container>
  );
}

export default Menu;
