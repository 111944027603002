const MORE_LIFE_MEETINGS = [
  "Quebrando barreiras para adesão a um estilo de vida mais saudável",
  "Gerenciamento do Stress",
  "Resolução de problemas",
  "Como agir em situações de risco",
  "Tornando positivos os pensamentos negativos",
  "Gatilhos psicológicos para os maus hábitos",
  "Sono saudável de A a Zzzzz",
  "Obtendo apoio e suporte familiar e social",
  "Deslizes e recidivas",
];

export default MORE_LIFE_MEETINGS;
