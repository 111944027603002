import * as Yup from "yup";

const schema = Yup.object().shape({
  consultationDate: Yup.string().required().min(10),
  yourAlimentation: Yup.string().required(),
  gainWeight: Yup.object().shape({
    moment: Yup.string(),
    speed: Yup.string(),
  }),
  greaterWeight: Yup.object().shape({
    weight: Yup.number().moreThan(0).required(),
    age: Yup.number().moreThan(0).required(),
  }),
  lowerWeight: Yup.object().shape({
    weight: Yup.number().moreThan(0).required(),
    age: Yup.number().moreThan(0).required(),
  }),
  actualWeight: Yup.string().required(),
  orientationDifficulties: Yup.string().required(),
  foodPreference: Yup.string().required(),
  uneatenFoods: Yup.string(),
  numberOfMeals: Yup.number().moreThan(0).required(),
  breakfast: Yup.bool().required(),
  morningSnack: Yup.bool().required(),
  lunch: Yup.bool().required(),
  afternoonSnack: Yup.bool().required(),
  dinner: Yup.bool().required(),
  eveningSnack: Yup.bool().required(),
  extraMeal: Yup.bool().required(),
  abuseFoodMeal: Yup.string(),
  foodCompulsion: Yup.bool().required(),
  eatingDisorder: Yup.array().of(Yup.string()).min(1),
  othersEatingDisorders: Yup.string(),
  betweenMeals: Yup.bool().required(),
  foodBetweenMeals: Yup.string(),
  foodIntolerances: Yup.array().of(Yup.string()).min(1),
  othersFoodIntolerances: Yup.string(),
  // foodEvaluation: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       evaluation: Yup.string().required(),
  //       frequency: Yup.string().required(),
  //       period: Yup.string().required(),
  //     })
  //   )
  //   .min(1),
  // liquidIngestion: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       evaluation: Yup.string().required(),
  //       frequency: Yup.string().required(),
  //       period: Yup.string().required(),
  //     })
  //   )
  //   .min(1),
  foodSurvey: Yup.object().shape({
    useSalt: Yup.bool().required(),
    useSugar: Yup.bool().required(),
    useSweetener: Yup.bool().required(),
    haveDieted: Yup.bool().required(),
    dietOrientation: Yup.string(),
    uneatenFoods: Yup.string(),
  }),
  digestionAndFoodFunction: Yup.object().shape({
    chewing: Yup.string().required(),
    intestinalFunction: Yup.string().required(),
    evaluationNumber: Yup.number().moreThan(0).required(),
    symptoms: Yup.array().of(Yup.string()).min(1),
  }),
  rest: Yup.object().shape({
    wakeUp: Yup.string().required(),
    sleepIn: Yup.string().required(),
    sleepHours: Yup.string().required(),
    sleepHoursInWeekend: Yup.string().required(),
    sleepDescription: Yup.string().required(),
    middleOfTheNight: Yup.bool().required(),
    middleOfTheNightToEat: Yup.bool().required(),
    middleOfTheNightFoods: Yup.string(),
  }),
  observations: Yup.string(),
  clientGoals: Yup.string(),
});

export default schema;
