import axios from "../middlewares/axios";

class ClientService {
  static getClients() {
    const url = `/client?sortBy=name`;
    return axios.get(url);
  }

  static update(id, data) {
    const url = `/client/${id}`;
    return axios.put(url, data);
  }
}

export default ClientService;
