import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { selectUser } from "../redux/ducks/Auth/selectors";
import { authSuccess } from "../redux/ducks/Auth/actions";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Menu } from "../components";
import { Container, PageContainer } from "./styles";
import SignIn from "../pages/SignIn";
import Anamnesis from "../pages/Anamnesis";

import ProfessionalsRoutes from "./Professionals";
import PsicologistRoutes from "./Psicologist";

function Routes() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  return (
    <Container>
      <Menu
        onUserClick={() => {
          dispatch(authSuccess(null));
        }}
      />
      <PageContainer>
        <Router>
          <Switch>
            <PublicRoute path="/login" component={SignIn} />
            <PrivateRoute path="/anamnesis" component={Anamnesis} />
            {!user && <Redirect from="/" to="login" />}
            {user &&
              user.profileType === "Psicologist" &&
              PsicologistRoutes.map((route) => route)}
            {user &&
              user.profileType !== "Psicologist" &&
              ProfessionalsRoutes.map((route) => route)}
          </Switch>
        </Router>
      </PageContainer>
    </Container>
  );
}

export default Routes;
