import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useField } from "@unform/core";
import InputMask from "react-input-mask";
import { Container, Label, Input } from "./styles";

const formatChars = {
  n: "[0-1]",
  m: "[0-9]",
  e: "[0-3]",
  d: "[0-9]",
  z: "[1-2]",
  y: "[0-9]",
};

const DateInput = ({ name, label, compact }) => {
  const inputRef = useRef();
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container
      error={!!error}
      onMouseEnter={() => {
        inputRef.current.focus();
      }}
    >
      <Label compact={compact}>{label}</Label>
      <InputMask
        formatChars={formatChars}
        mask="ed/nm/zyyy"
        alwaysShowMask={false}
        maskChar={null}
        defaultValue={defaultValue || moment().format("DD/MM/YYYY")}
        placeholder="DD/MM/YYYY"
      >
        {(inputProps) => (
          <Input ref={inputRef} compact={compact} {...inputProps} />
        )}
      </InputMask>
    </Container>
  );
};

DateInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  compact: PropTypes.bool,
};

export default DateInput;
