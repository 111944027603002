import React, { InputHTMLAttributes, useCallback } from "react";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
import "./styles.scoped.scss";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
}

const RadioGroup: React.FC<RadioProps> = (props) => {
  const { label, name, options } = props;
  const { control, setValue } = useFormContext();

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  return (
    <Controller
      render={({ field }) => (
        <div className="container">
          <label>{label}</label>
          <div className={`items ${props.direction}`}>
            {getOptions().map((option) => (
              <div
                key={option.value}
                className={classNames({
                  option: true,
                  checked: field.value === option.value,
                  compact: props.compact,
                })}
                onClick={() => {
                  setValue(name, option.value);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
      control={control}
      name={name}
    />
  );
};

export default RadioGroup;
