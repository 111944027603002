export const CARDIOLOGY_EXAMS = [
  {
    label: "Ecocardiograma",
    name: "ecocardiograma",
    suffix: "",
    references: [],
  },
  {
    label: "TE",
    name: "TE",
    suffix: "",
    references: [],
  },
  {
    label: "MAPA",
    name: "MAPA",
    suffix: "",
    references: [],
  },
  {
    label: "Holter",
    name: "Holter",
    suffix: "",
    references: [],
  },
  {
    label: "Escore de Cálcio",
    name: "EscoreCalcio",
    suffix: "",
    references: [],
  },
  {
    label: "ECG",
    name: "ECG",
    suffix: "",
    references: [],
  },
];

export const LABORATORY_EXAMS = [
  {
    label: "Glicemia",
    name: "glicemia",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 100, label: "Normal: < 100 mg/dL" },
      { min: 100, max: 126, label: "Pré-diabetes: 100 a 126 mg/dL" },
      { min: 126, max: 100000000, label: "DM: >= 126 mg/dL" },
    ],
  },
  {
    label: "HbA1c (hemoglobina glicada)",
    name: "hba1c",
    suffix: " %",
    references: [
      { min: 0, max: 5.6, label: "Normal: < 5,6%" },
      { min: 5.6, max: 6.4, label: "Risco para DM: 5,6% a 6,4%" },
      { min: 6.5, max: 100000000, label: "DM: >= 6,5%" },
    ],
  },
  {
    label: "Teste Oral de Tolerância à Glicose (TOTG)",
    name: "totg",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 140, label: "Normal: < 140 mg/dL" },
      { min: 140, max: 200, label: "Pré-diabetes: 140 a 200 mg/dL" },
      { min: 200, max: 100000000, label: "DM: >= 200 mg/dL" },
    ],
  },
  {
    label: "Colesterol total",
    name: "colesterolTotal",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 140, label: "Normal: < 200 mg/dL" },
      { min: 200, max: 240, label: "Limítrofe: 200 a 240 mg/dL" },
      { min: 240, max: 100000000, label: "Alto: >= 200 mg/dL" },
    ],
  },
  {
    label: "Colesterol HDL",
    name: "colesterolHDL",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 40, label: "Baixo: < 40 mg/dL" },
      { min: 40, max: 60, label: "Limítrofe: 40 a 60 mg/dL" },
      { min: 60, max: 100000000, label: "Desejável: >= 60 mg/dL" },
    ],
  },
  {
    label: "Triglicerídeos",
    name: "triglicerídeos",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 150, label: "Ótimo: < 150 mg/dL" },
      { min: 150, max: 199, label: "Limítrofe: 150 a 199 mg/dL" },
      { min: 200, max: 100000000, label: "Alto: >= 200 mg/dL" },
    ],
  },
  {
    label: "Colesterol LDL",
    name: "colesterolLDL",
    suffix: " MG/DL",
    references: [
      { min: 1, max: 100, label: "Ótimo: < 100 mg/dL" },
      { min: 100, max: 129, label: "Desejável: 100 a 129 mg/dL" },
      { min: 130, max: 159, label: "Limítrofe: 130 a 159 mg/dL" },
      { min: 160, max: 100000000, label: "Desejável: >= 160 mg/dL" },
    ],
  },
  {
    label: "Ácido úrico",
    name: "acidoUrico",
    suffix: " mg/ dL",
    references: [
      {
        min: 3.4,
        max: 7,
        label: "Mulheres 2,4 a 5,7 mg/ dL. Homens 3,4 a 7,0 mg/ dL.",
      },
    ],
  },
  {
    label: "Hematócrito",
    name: "hematocrito",
    suffix: " %",
    references: [{ min: 35, max: 47, label: "35 a 47%" }],
  },
  {
    label: "Hemoglobina",
    name: "hemoglobina",
    suffix: " g/dL",
    references: [{ min: 11, max: 16, label: "11 a 16g/dL" }],
  },
  {
    label: "Eritrócitos",
    name: "eritrocitos",
    suffix: " milhões/ uL",
    references: [{ min: 3.9, max: 5.3, label: "3,90 a 5,30 milhões/ uL" }],
  },
  {
    label: "Leucócitos",
    name: "leucocitos",
    suffix: " uL",
    references: [{ min: 3600, max: 11000, label: "3600 a 11000/ uL" }],
  },
  {
    label: "Plaquetas",
    name: "plaquetas",
    suffix: " mil/ uL",
    references: [{ min: 150, max: 400, label: "150 a 400 mil/ uL" }],
  },
  {
    label: "gama GT",
    name: "gamaGT",
    suffix: " UI/L",
    references: [{ min: 6, max: 42, label: "6 a 42 U/L" }],
  },
  {
    label: "Transaminase Glutamico Oxalacética (TGO)",
    name: "TGO",
    suffix: " U/L",
    references: [{ min: 0, max: 32, label: "até 32 U/L" }],
  },
  {
    label: "Transaminase Glutamico Pirúvica (TGP)",
    name: "TGP",
    suffix: " U/L",
    references: [{ min: 0, max: 33, label: "até 33 U/L" }],
  },
  {
    label: "TP",
    name: "TP",
    suffix: "",
    references: [],
  },
  {
    label: "Bilirrubinas",
    name: "bilirrubinas",
    suffix: " MG/DL",
    references: [{ min: 0, max: 1.2, label: "adultos até 1,2 mg/dL" }],
  },
  {
    label: "Ferritina",
    name: "ferritina",
    suffix: " nanog/ mL",
    references: [
      { min: 30, max: 400, label: "Homens: 30 a 400 nanog/ mL" },
      { min: 13, max: 150, label: "Mulheres: 13 a 150 nanog/ mL" },
    ],
  },
  {
    label: "Saturação transferrina",
    name: "saturacaoTransferrina",
    suffix: " %",
    references: [{ min: 16, max: 45, label: "15 a 45%" }],
  },
  {
    label: "Genotipagem hemocromatose",
    name: "saturacaoTransferrina",
    references: [
      { min: -1, max: -1, label: "Negativo; Heterozigoto; Homozigoto" },
    ],
  },
  {
    label: "Creatinina",
    name: "Creatinina",
    suffix: " mg/ dL",
    references: [
      {
        min: 0.5,
        max: 0.9,
        label: "Homens: 0,7 a 1,2 mg/ dL. Mulheres 0,5 a 0,9 mg/ dL.",
      },
    ],
  },
  {
    label: "Exame Qualitativo de Urina (EQU)",
    name: "equ",
    references: [],
  },
  {
    label: "Urocultura/teste",
    name: "urocultura",
    references: [],
  },
  {
    label: "Microalbuminúria",
    name: "microalbuminuria",
    suffix: " mcg/ mg",
    references: [
      {
        min: -100,
        max: 30,
        label: "Inferior a 30 mcg/ mg.",
      },
    ],
  },
  {
    label: "Taxa de Filtração Glomerular (TFG)",
    name: "tfg",
    suffix: " mL/ min/ 1,73m2",
    references: [
      {
        min: -100,
        max: 60,
        label: ">= 60 mL/ min/ 1,73 m2",
      },
    ],
  },
  {
    label: "Sódio",
    name: "sodio",
    suffix: " mmol/L",
    references: [{ min: 136, max: 145, label: "136 a 145 mmol/ L" }],
  },
  {
    label: "Potássio",
    name: "potassio",
    suffix: " mmol/L",
    references: [{ min: 3.5, max: 5.1, label: "3,5 a 5,1 mmol/L" }],
  },
  {
    label: "Cálcio total",
    name: "calcioTotal",
    suffix: " mg/ dL",
    references: [{ min: 8.4, max: 10.2, label: "Normal: 8,4 a 10,2 mg/ dL" }],
  },
  {
    label: "Cálcio iônico",
    name: "calcioIonico",
    suffix: " mmol/ L",
    references: [{ min: 1.16, max: 1.32, label: "1,160 a 1,320 mmol/ dL" }],
  },
  {
    label: "Creatinofosfoquinase (CPK)",
    name: "CPK",
    suffix: " U/L",
    references: [{ min: 26, max: 192, label: "26 a 192 U/L" }],
  },
  {
    label: "Paratormonio (PTH)",
    name: "PTH",
    suffix: " pg/ mL",
    references: [{ min: 12, max: 65, label: "Normal: entre 12 e 65 pg/mL" }],
  },
  {
    label: "Vitamina D",
    name: "vitaminaD",
    suffix: " ng/ml",
    references: [
      { min: -1000, max: 20, label: "Deficiência < 20 ng/ml " },
      { min: 20, max: 60, label: "Normalidade 20 a 60 ng/ml" },
    ],
  },
  {
    label: "Vitamina B12",
    name: "vitaminaB12",
    suffix: " ng/ L",
    references: [{ min: 190, max: 900, label: "Normal: 190 a 900 ng/ L" }],
  },
  {
    label: "PCR ultrassensível",
    name: "PCR",
    suffix: " mg/ L",
    references: [{ min: -100, max: 3, label: "Normal: até 3,0 mg/L" }],
  },
  {
    label: "Velocidade de Sedimentação Globular (VSG)",
    name: "VSG",
    suffix: "",
    references: [],
  },
  {
    label: "Insulina",
    name: "insulina",
    suffix: "",
    references: [],
  },
  {
    label: "Leptina",
    name: "leptina",
    suffix: "",
    references: [],
  },
  {
    label: "TSH",
    name: "TSH",
    suffix: " mcUI/ mL",
    references: [{ min: 0.55, max: 4.78, label: "0,55 a 4,78 mcUI/ mL" }],
  },
  {
    label: "Tiroxina Livre (T4 livre)",
    name: "T4Livre",
    suffix: " ng/ dL",
    references: [{ min: 0.89, max: 1.76, label: "0,89 a 1,76 ng/ dL" }],
  },
  {
    label: "Ac anti TPO",
    name: "antiTPO",
    suffix: "",
    references: [],
  },
];
