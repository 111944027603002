import axios from "../middlewares/axios";

class MoreLifeService {
  static get(id) {
    const url = `/morelife/${id}`;
    return axios.get(url);
  }

  static getAll(consultationDate) {
    const url = `/morelife?consultationDate=${consultationDate}`;
    return axios.get(url);
  }

  static save(data) {
    const url = `/morelife`;
    return axios.post(url, data);
  }
}

export default MoreLifeService;
