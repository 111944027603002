import axios from "../middlewares/axios";

class AnamnesisService {
  static getAllAnamnesis(anamnesis, clientId) {
    const url = `/anamnesis/${anamnesis}?client=${clientId}`;
    return axios.get(url);
  }

  static insertAnamnesis(anamnesis, data) {
    const url = `/anamnesis/${anamnesis}`;
    return axios.post(url, data);
  }
}

export default AnamnesisService;
