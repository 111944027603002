import styled from "styled-components";
import AddSVG from "../../../assets/icons/add.svg";
import RemoveSVG from "../../../assets/icons/delete.svg";
import DeleteSVG from "../../../assets/icons/remove.svg";

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: var(--font-heavy);
  font-size: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: var(--purple-dark);
`;

export const Subtitle = styled.h2`
  font-family: var(--font-medium);
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);

  strong {
    font-family: var(--font-heavy);
  }
`;

export const RemoveIcon = styled.img.attrs({
  src: RemoveSVG,
})`
  position: absolute;
  top: -7px;
  right: 0;
  cursor: pointer;
  z-index: 100;
`;

export const AddIcon = styled.img.attrs({
  src: AddSVG,
})`
  margin-right: 25px;
`;

export const DeleteIcon = styled.img.attrs({
  src: DeleteSVG,
})`
  margin-right: 25px;
`;

export const AddButton = styled.button.attrs({
  type: "button",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 321px;
  height: 68px;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);

  :hover {
    border-color: var(--purple);
  }
`;

export const DeleteButton = styled(AddButton)`
  color: var(--orange);
  :hover {
    border-color: var(--orange);
  }
`;

export const ExerciseWrapper = styled.div`
  width: 350px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  :hover {
    cursor: pointer;
    border-color: var(--success);
    transform: scale(1.03);
  }
`;

export const ExerciseCover = styled.img`
  height: 89px;
  width: 100%;
  object-fit: cover;
`;
