import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import {
  H1,
  FoodList,
  FoodItem,
  DateInput,
  TextInput,
  NumberInput,
  Options,
  FrequencyOptions,
  Loading,
  LeftPanel,
} from "../../components";
import { LoadingPurple } from "../../components/Loading";
import { MealItem } from "../../components/FoodList";
import { Row, Col } from "reactstrap";
import {
  Container,
  FooterContainer,
  SaveButton,
  Title,
  Subtitle,
  SearchInput,
} from "./styles";
import { selectToggled } from "../../redux/ducks/Client/selectors";
import { selectLoading } from "../../redux/ducks/Anamnesis/selectors";
import NutriSchema from "./schemas/Nutri";
import FoodsService from "../../services/FoodsService";
import MealsService from "../../services/MealsService";

import DAYS_OF_WEEK from "../../utils/daysOfWeek";
import PERIODS from "../../utils/periods";

import ClientContext from "../../context/ClientContext";

const Anamnesis = () => {
  const clientToggled = useSelector(selectToggled);
  const loading = useSelector(selectLoading);
  const [loadingFoods, setLoadingFoods] = useState(false);
  const formRef = useRef(null);
  const [foods, setFoods] = useState([]);
  const [meals, setMeals] = useState([]);
  const [editingMeal, setEditingMeal] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [breakfast, setBreakfast] = useState(null);
  const [morningSnack, setMorningSnack] = useState(null);
  const [lunch, setLunch] = useState(null);
  const [afternoonSnack, setAfternoonSnack] = useState(null);
  const [dinner, setDinner] = useState(null);
  const [eveningSnack, setEveningSnack] = useState(null);
  const [extraMeal, setExtraMeal] = useState(null);
  const [currentOperation, setCurrentOperation] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [touched, setTouched] = useState(false);
  const [weekMeals, setWeekMeals] = useState({});
  const [currentData, setCurrentData] = useState({});

  const {
    saveAnamnesis,
    setAnamnesisType,
    currentAnamnesis,
    client,
  } = useContext(ClientContext);

  const getCurrentAnamnesisData = useCallback(() => {
    return {
      ...currentAnamnesis,
      breakfast: _.get(currentAnamnesis, "meals.breakfast.do"),
      morningSnack: _.get(currentAnamnesis, "meals.morningSnack.do"),
      lunch: _.get(currentAnamnesis, "meals.lunch.do"),
      afternoonSnack: _.get(currentAnamnesis, "meals.afternoonSnack.do"),
      dinner: _.get(currentAnamnesis, "meals.dinner.do"),
      eveningSnack: _.get(currentAnamnesis, "meals.eveningSnack.do"),
      extraMeal: _.get(currentAnamnesis, "meals.extraMeal.do"),
    };
  }, [currentAnamnesis]);

  useEffect(() => {
    setAnamnesisType("nutri");
  }, [setAnamnesisType]);

  useEffect(() => {
    getFoods();
  }, []);

  useEffect(() => {
    if (loading === false) {
      setTouched(false);
    }
  }, [loading]);

  useEffect(() => {
    setCurrentData(currentAnamnesis);
  }, [currentAnamnesis]);

  useEffect(() => {
    [
      ["breakfast", setBreakfast],
      ["morningSnack", setMorningSnack],
      ["lunch", setLunch],
      ["afternoonSnack", setAfternoonSnack],
      ["dinner", setDinner],
      ["eveningSnack", setEveningSnack],
      ["extraMeal", setExtraMeal],
    ].forEach((operations) => {
      const [path, set] = operations;
      const doHave = _.get(currentAnamnesis, `meals.${path}.do`, false);

      set(
        doHave
          ? _.get(currentAnamnesis, `meals.${path}.foods`, []).map((f) => {
              const food = _.find(foods, (food) => food.id === f.id);
              return { ...f, ...food };
            })
          : null
      );
    });
    getWeekMeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnamnesis, foods]);

  const getFoods = async () => {
    setLoadingFoods(true);

    try {
      const foodsResponse = await FoodsService.getFoods();
      setFoods(foodsResponse.data.data);

      const mealsResponse = await FoodsService.getMeals();
      setMeals(mealsResponse.data.data);
    } catch (e) {}

    setLoadingFoods(false);
  };

  const getWeekMeals = async () => {
    try {
      const today = moment().format("DD/MM/YYYY");
      const weekMealsResponse = await MealsService.getWeekMeals(
        client._id,
        today
      );
      setWeekMeals(weekMealsResponse.data.data);
    } catch (e) {
      setWeekMeals([]);
    }
  };

  const getCurrentSet = () => {
    if (currentOperation === "breakfast") {
      return setBreakfast;
    }
    if (currentOperation === "morningSnack") {
      return setMorningSnack;
    }
    if (currentOperation === "lunch") {
      return setLunch;
    }
    if (currentOperation === "afternoonSnack") {
      return setAfternoonSnack;
    }
    if (currentOperation === "dinner") {
      return setDinner;
    }
    if (currentOperation === "eveningSnack") {
      return setEveningSnack;
    }
    if (currentOperation === "extraMeal") {
      return setExtraMeal;
    }

    return () => {};
  };

  const updateWeekMeals = async () => {
    try {
      const id = weekMeals._id;

      const weekDays = weekMeals.weekDays.map((w) => {
        const weekDay = w.weekDay;
        const plan = w.plan.map((plan) => {
          const period = plan.period;
          const meals = plan.meals.map((meal) => meal.id);

          return { period, meals };
        });

        return { weekDay, plan };
      });

      const data = { weekDays };

      await MealsService.update(id, data);
    } catch (e) {}
  };

  const handleSubmit = async (form) => {
    const getFoodItems = (list) => {
      if (!list) {
        return [];
      }
      return list.map(({ id, portion }) => ({ id, portion }));
    };

    try {
      const data = {
        _id: currentAnamnesis._id,
        client: client._id,
        responsibleProfessional: "5f523484e912ff4a31221602",
        meals: {
          breakfast: { do: form.breakfast, foods: getFoodItems(breakfast) },
          morningSnack: {
            do: form.morningSnack,
            foods: getFoodItems(morningSnack),
          },
          lunch: { do: form.lunch, foods: getFoodItems(lunch) },
          afternoonSnack: {
            do: form.afternoonSnack,
            foods: getFoodItems(afternoonSnack),
          },
          dinner: { do: form.dinner, foods: getFoodItems(dinner) },
          eveningSnack: {
            do: form.eveningSnack,
            foods: getFoodItems(eveningSnack),
          },
          extraMeal: { do: form.extraMeal, foods: getFoodItems(extraMeal) },
        },
        ...form,
      };

      formRef.current.setErrors({});
      await updateWeekMeals();

      await NutriSchema.validate(data, {
        abortEarly: false,
      });

      await saveAnamnesis(data, "nutri");
      setTouched(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          formRef.current.setFieldError(error.path, error.message);
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const onChangeFood = (food, list, set) => {
    const newFoods = list.map((f) => {
      if (f.id === food.id) {
        return food;
      }

      return f;
    });

    set(newFoods);
  };

  const renderResumo = () => (
    <>
      <Row>
        <Col xs="12" sm="12" lg="4">
          <DateInput name="consultationDate" label="Data da Consulta" />
        </Col>
      </Row>
      <Row>
        <Col>
          <H1 color="nutri">Anamnese Alimentar e Nutricional</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="yourAlimentation"
            label="Como você considera sua alimentação"
            multiple={false}
            options={["Muito ruim", "Ruim", "Razoável", "Boa", "Muito boa"].map(
              (e) => ({
                label: e,
                value: e,
              })
            )}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="gainWeight.moment"
            label="Tempo de Início de Ganho de Peso"
            placeholder="Digite aqui o tempo de início de ganho de peso"
          />
        </Col>
        <Col>
          <TextInput
            name="gainWeight.speed"
            label="Velocidade de Ganho de Peso"
            placeholder="Digite aqui a velocidade de ganho de peso"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            name="greaterWeight"
            label="Menor Peso/Idade"
            minMaxNames={["Peso", "Idade"]}
            minMaxIds={["greaterWeight.weight", "greaterWeight.age"]}
            showInput={false}
            showMinMax
          />
        </Col>
        <Col>
          <NumberInput
            name="lowerWeight"
            label="Maior Peso/Idade"
            minMaxNames={["Peso", "Idade"]}
            minMaxIds={["lowerWeight.weight", "lowerWeight.age"]}
            showInput={false}
            showMinMax
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="actualWeight"
            label="Em relação ao seu peso, atualmente está"
            multiple={false}
            options={["Perdendo", "Mantendo", "Ganhando"].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="orientationDifficulties"
            label="Dificuldades de adesão às orientações"
            placeholder="Descreva aqui eventuais dificuldades"
          />
        </Col>
      </Row>
    </>
  );

  const renderPadraoAlimentar = () => (
    <>
      <Row>
        <Col>
          <H1 color="nutri">Padrão Alimentar</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="foodPreference"
            label="Qual seu padrão alimentar?"
            multiple={false}
            options={["Tradicional", "Vegetariano", "Vegano"].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity:
              _.get(currentData, "foodPreference") === "Tradicional" ? 0.2 : 1,
            pointerEvents:
              _.get(currentData, "foodPreference") === "Tradicional"
                ? "none"
                : "all",
          }}
        >
          <TextInput
            name="uneatenFoods"
            label="Alimentos não consumidos"
            placeholder="Liste aqui os alimentos"
          />
        </Col>
      </Row>
    </>
  );

  const renderMealRecordatorio = (id, label, list, setList) => (
    <>
      <Row>
        <Col>
          <Options
            name={id}
            label={label}
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => {
              setTouched(true);
              setList(formRef.current.getData()[id] === true ? [] : null);
            }}
          />
        </Col>
      </Row>
      {list && (
        <Row>
          <Col>
            <FoodList
              foods={list}
              onAdd={() => {
                setCurrentOperation(id);
                setCurrentList([...list]);
              }}
              onChangeFood={(food) => onChangeFood(food, list, setList)}
              onRemove={(food) => {
                onChangeFood(
                  food,
                  list.filter((f) => f.id !== food.id),
                  setList
                );
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );

  const renderRecordatorioAlimentar = () => (
    <>
      <Row>
        <Col>
          <H1 color="nutri">Recordatório Alimentar</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="foodSurvey.uneatenFoods"
            label="Alimentos que não consome"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            name="numberOfMeals"
            label="Quantas refeições por dia?"
            suffix=" refeições diárias"
          />
        </Col>
      </Row>
      {renderMealRecordatorio(
        "breakfast",
        "Realiza café da manhã?",
        breakfast,
        setBreakfast
      )}
      {renderMealRecordatorio(
        "morningSnack",
        "Realiza lanche da manhã?",
        morningSnack,
        setMorningSnack
      )}
      {renderMealRecordatorio("lunch", "Realiza almoço?", lunch, setLunch)}
      {renderMealRecordatorio(
        "afternoonSnack",
        "Realiza lanche da tarde?",
        afternoonSnack,
        setAfternoonSnack
      )}
      {renderMealRecordatorio("dinner", "Realiza janta?", dinner, setDinner)}
      {renderMealRecordatorio(
        "eveningSnack",
        "Realiza ceia?",
        eveningSnack,
        setEveningSnack
      )}
      {renderMealRecordatorio(
        "extraMeal",
        "Realiza refeição extra?",
        extraMeal,
        setExtraMeal
      )}

      <Row>
        <Col>
          <TextInput
            name="abuseFoodMeal"
            label="você exagera no consumo alimentar em alguma refeição?"
            placeholder="Descreva aqui eventuais exageros alimentares"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="foodCompulsion"
            label="Compulsão alimentar"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="eatingDisorder"
            label="Transtorno alimentar"
            otherId="othersEatingDisorders"
            options={["Não", "Bulimia", "Anorexia", "Ortorexia", "Outros"].map(
              (e) => ({
                label: e,
                value: e,
              })
            )}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="betweenMeals"
            label="Possui o hábito de beliscar ou buscar ativamente alimentos entre as refeições?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "betweenMeals") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "betweenMeals") ? "all" : "none",
          }}
        >
          <TextInput
            name="foodBetweenMeals"
            label="Quais alimentos costuma beliscar entre refeições?"
            placeholder="Liste aqui os alimentos"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="foodIntolerances"
            otherId="othersFoodIntolerances"
            label="Alguma intolerância ou alergia alimentar?"
            options={["Não", "Lactose", "Glúten", "Outros"].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FrequencyOptions
            name="foodEvaluation"
            label="Frequência alimentar"
            showLeftAndRight
            leftOptions={["1x", "2x", "3x", "4x", "5x", "Mais de 5x"]}
            rightOptions={["dia", "semana", "mês"]}
            options={[
              "Saladas",
              "Frutas",
              "Doces",
              "Frituras",
              "Peixes",
              "Embutidos",
              "Temperos artificais",
            ].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FrequencyOptions
            name="liquidIngestion"
            label="Ingestão de líquidos"
            showLeftAndRight
            leftOptions={[
              "250ml",
              "500ml",
              "1000ml",
              "1500ml",
              "2000ml",
              "2000ml+",
            ]}
            rightOptions={["Dia", "Semana", "Mês", "Ano"]}
            options={[
              "Água",
              "Sucos naturais",
              "Energéticos",
              "Sucos Artificiais",
              "Chás",
              "Cafés",
              "Refrigerantes",
              "Álcool",
              "Outros",
            ].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Options
            name="foodSurvey.useSalt"
            label="Usa sal em alimentos prontos?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => setTouched(true)}
          />
        </Col>
        <Col>
          <Options
            name="foodSurvey.useSugar"
            label="Usa açúcar?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="foodSurvey.useSweetener"
            label="Usa adoçante?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => setTouched(true)}
          />
        </Col>
        <Col>
          <Options
            name="foodSurvey.haveDieted"
            label="Já fez dieta?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "foodSurvey.haveDieted") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "foodSurvey.haveDieted")
              ? "all"
              : "none",
          }}
        >
          <TextInput
            name="foodSurvey.dietOrientation"
            label="Orientado por quem?"
            placeholder=""
          />
        </Col>
      </Row>
    </>
  );

  const renderDigestao = () => (
    <>
      <Row>
        <Col>
          <H1 color="nutri">Disgestão e Intestino</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="digestionAndFoodFunction.chewing"
            label="Como é sua mastigação?"
            multiple={false}
            options={["Lenta", "Normal", "Rápida"].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="digestionAndFoodFunction.intestinalFunction"
            label="Como é sua função intestinal?"
            multiple={false}
            options={["Constipado", "Normal", "Diarréia"].map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            name="digestionAndFoodFunction.evaluationNumber"
            label="Quantas evacuações na semana"
            suffix=" evacuações semanais"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="digestionAndFoodFunction.symptoms"
            label="Você possui alguns dos sintomas digestivos?"
            options={["Não", "Refluxo", "Gastrite", "Náuseas", "Vômitos"].map(
              (e) => ({
                label: e,
                value: e,
              })
            )}
            onChange={() => setTouched(true)}
          />
        </Col>
      </Row>
    </>
  );

  const renderSono = () => (
    <>
      <Row>
        <Col>
          <H1 color="nutri">Sono</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            name="rest"
            label="Horário que acorda e dorme"
            format="##:## H"
            suffix=" H"
            showInput={false}
            showMinMax
            minMaxNames={["Acorda", "Dorme"]}
            minMaxIds={["rest.wakeUp", "rest.sleepIn"]}
          />
        </Col>
        <Col>
          <NumberInput
            name="rest"
            label="Horas de sono"
            format="## H"
            suffix=" H"
            showInput={false}
            showMinMax
            minMaxNames={["Semana", "Fim de semana"]}
            minMaxIds={["rest.sleepHours", "rest.sleepHoursInWeekend"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="rest.sleepDescription"
            label="Como costuma ser o sono"
            placeholder="Descreva aqui o sono"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Options
            name="rest.middleOfTheNight"
            label="Acorda no meio da noite?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => {
              setTouched(true);
            }}
          />
        </Col>
        <Col>
          <Options
            name="rest.middleOfTheNightToEat"
            label="Acorda no meio da noite para comer?"
            multiple={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "rest.middleOfTheNightToEat") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "rest.middleOfTheNightToEat")
              ? "all"
              : "none",
          }}
        >
          <TextInput
            name="rest.middleOfTheNightFoods"
            label="Quais alimentos come no meio da noite?"
            placeholder="Liste aqui os alimentos"
          />
        </Col>
      </Row>
    </>
  );

  const renderPlanoAnimentar = () => (
    <>
      <Row>
        <Col>
          <H1 color="nutri">Plano Alimentar</H1>
        </Col>
      </Row>
      {_.isEmpty(weekMeals) && (
        <p style={{ textAlign: "center", color: "var(--text-light)" }}>
          Preencha e salve a ficha da nutri
        </p>
      )}
      {_.get(weekMeals, "weekDays", []).map(({ plan, weekDay }, index) => (
        <>
          {plan.map((p, planIndex) => (
            <>
              <Row>
                <Col>
                  <H1 color="nutri">
                    {DAYS_OF_WEEK[weekDay]} - {PERIODS[p.period]}
                  </H1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FoodList
                    meals={p.meals}
                    onAdd={() => {
                      setEditingMeal({
                        path: ["weekDays", index, "plan", planIndex, "meals"],
                        name: `${DAYS_OF_WEEK[weekDay]} - ${PERIODS[p.period]}`,
                      });
                    }}
                    onRemove={(meal) => {
                      const newPlans = p.meals.filter((m) => m.id !== meal.id);
                      let newWealMeals = { ...weekMeals };
                      newWealMeals = _.set(
                        newWealMeals,
                        ["weekDays", index, "plan", planIndex, "meals"],
                        newPlans
                      );
                      setTouched(true);
                      setWeekMeals(newWealMeals);
                      setEditingMeal(null);
                    }}
                  />
                </Col>
              </Row>
            </>
          ))}
        </>
      ))}
    </>
  );

  const renderLeftPanel = () => (
    <LeftPanel
      open={currentOperation.length > 0}
      onClose={() => setCurrentOperation("")}
    >
      <Title>Cadastro de alimentos</Title>
      <Subtitle>
        Você está adicionando <strong>Café da manhã</strong>
      </Subtitle>
      <SearchInput
        placeholder="Pesquisar Alimentos"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          marginLeft: -17,
          marginRight: -17,
        }}
      >
        {(searchText.length === 0
          ? foods
          : foods.filter((food) =>
              food.description.toUpperCase().includes(searchText.toUpperCase())
            )
        ).map((food) => (
          <FoodItem
            key={food.id}
            food={food}
            selected={currentList.filter((f) => f.id === food.id).length > 0}
            onClick={() => {
              if (currentList.filter((f) => f.id === food.id).length > 0) {
                setCurrentList(currentList.filter((f) => f.id !== food.id));
              } else {
                setCurrentList([...currentList, food]);
              }
            }}
          />
        ))}
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <SaveButton
          style={{
            color: "var(--text)",
            marginRight: 10,
            backgroundColor: "transparent",
          }}
          onClick={() => setCurrentOperation("")}
        >
          Cancelar
        </SaveButton>
        <SaveButton
          type="button"
          style={{ width: "100%" }}
          onClick={() => {
            getCurrentSet()([...currentList]);
            setCurrentOperation("");
          }}
        >
          Salvar Alterações
        </SaveButton>
      </div>
    </LeftPanel>
  );

  const renderLeftPanelMeals = () => (
    <LeftPanel
      open={!_.isEmpty(editingMeal)}
      onClose={() => setEditingMeal(null)}
    >
      <Title>Adicionar refeições</Title>
      <Subtitle>
        Você está adicionando em <strong>{_.get(editingMeal, "name")}</strong>
      </Subtitle>
      <SearchInput
        placeholder="Pesquisar refeições"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          marginLeft: -17,
          marginRight: -17,
        }}
      >
        {(searchText.length === 0
          ? meals
          : meals.filter((meal) =>
              meal.name.toUpperCase().includes(searchText.toUpperCase())
            )
        ).map((meal) => (
          <MealItem
            key={meal.id}
            meal={meal}
            onClick={() => {
              const newPlans = [
                ..._.get(weekMeals, editingMeal.path, []),
                meal,
              ];
              let newWealMeals = { ...weekMeals };
              newWealMeals = _.set(newWealMeals, editingMeal.path, newPlans);
              setTouched(true);
              setWeekMeals(newWealMeals);
              setEditingMeal(null);
            }}
          />
        ))}
      </div>
    </LeftPanel>
  );

  const renderSave = () => (
    <FooterContainer visible={touched} clientToggled={clientToggled}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  if (loadingFoods) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <Form
      key={currentAnamnesis._id}
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={getCurrentAnamnesisData()}
      onChange={() => setTouched(true)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <Container>
        {renderResumo()}
        {renderPadraoAlimentar()}
        {renderRecordatorioAlimentar()}
        {renderDigestao()}
        {renderSono()}
        {renderPlanoAnimentar()}
      </Container>
      {renderSave()}
      {renderLeftPanel()}
      {renderLeftPanelMeals()}

      <Row>
        <Col>
          <TextInput
            name="observations"
            label="Observações adicionais"
            placeholder="Descreva aqui oberservações adicionais"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="clientGoals"
            label="Metas acordadas com o cliente"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Anamnesis;
