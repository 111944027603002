import * as Yup from "yup";

const MeetingSchema = Yup.object().shape({
  consultationDate: Yup.string().required().min(10),
  hour: Yup.string().required().min(5),
  clients: Yup.array().of(Yup.string()).required().min(1),
  theme: Yup.string().required(),
  positivePoints: Yup.string(),
  negativePoints: Yup.string(),
  difficulties: Yup.string(),
  reviews: Yup.string(),
  suggestions: Yup.string(),
});

export default MeetingSchema;
