import React, { useEffect, useState } from "react";

import {
  Container,
  Wrapper,
  Option,
  Label,
  Input,
  Row,
  Col,
  CloseButton,
} from "./styles";

function Deseases({ deseases, values, onChange }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      deseases.map((item) => {
        let valueItem = {};
        const filtered = (values || []).filter((v) => v.value === item.value);
        if (filtered.length > 0) {
          valueItem = {
            selected: true,
            ...filtered[0],
          };
        }
        return {
          ...item,
          ...valueItem,
        };
      })
    );
  }, [deseases, values]);

  useEffect(() => {
    onChange(items.filter((item) => item.selected));
  }, [items, onChange]);

  const onClick = (desease) => {
    setItems(
      items.map((item) => {
        return item.value !== desease.value
          ? item
          : {
              ...item,
              selected: !item.selected,
            };
      })
    );
  };

  const onChangeProperty = (desease, key, value) => {
    setItems(
      items.map((item) => {
        return item.value !== desease.value
          ? item
          : {
              ...item,
              [key]: value,
            };
      })
    );
  };

  return (
    <Container>
      <Wrapper>
        {items.map((desease) => (
          <Option
            key={desease.value}
            selected={desease.selected}
            onClick={() => {
              if (!desease.selected) {
                onClick(desease);
              }
            }}
          >
            {desease.label}
            {desease.selected && (
              <>
                <CloseButton
                  onClick={() => {
                    onClick(desease);
                  }}
                />
                <br />
                <Row>
                  <Col>
                    <Label>Observação</Label>
                    <Input
                      value={desease.observation}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(desease, "observation", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Status</Label>
                    <Input
                      value={desease.status}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(desease, "status", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                  <Col>
                    <Label>Nome do Médico</Label>
                    <Input
                      value={desease.doctor}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(desease, "doctor", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Tratamento</Label>
                    <Input
                      value={desease.treatment}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(desease, "treatment", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Option>
        ))}
      </Wrapper>
    </Container>
  );
}

export default Deseases;
