import React, { useContext, useEffect } from "react";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { Row, Col } from "reactstrap";
import NumberInput from "../../../components/Form/NumberInput";
import DateInput from "../../../components/Form/DateInput";
import TextInput from "../../../components/Form/TextInput";
import CheckGroup from "../../../components/Form/CheckGroup";
import RadioGroup from "../../../components/Form/RadioGroup";
import Select from "../../../components/Form/Select";
import UploadFiles from "../../../components/Form/UploadFiles";
import HEALTH_PROBLEMS from "../../../utils/healthProblems";
import MUSCLE_ACHES from "../../../utils/muscleAches";
import MOVEMENT_DIFFICULTY from "../../../utils/movementDifficulty";
import PHYSICAL_ACTIVITIES from "../../../utils/physicalActivities";
import MuscleSVG from "../../../assets/images/muscles.svg";
import SystemContext from "../../../context/SystemContext";
import ClientContext from "../../../context/ClientContext";
import "./styles.scoped.scss";

interface MethodsProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const InformacoesGerais: React.FC<MethodsProps> = (props) => {
  const { downloadReport } = useContext(ClientContext);
  return (
    <>
      <Row>
        <Col>
          <DateInput name="consultationDate" label="Data da Consulta" />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            className="saveButton"
            type="button"
            onClick={() => {
              downloadReport();
            }}
          >
            Baixar laudo
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="tech">Informações Gerais</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput name="goal" label="Objetivos do Paciente" />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="healthProblems.value"
            label="Problemas de Saúde"
            options={HEALTH_PROBLEMS}
            renderOther={
              <TextInput name="healthProblems.other" label="Outros" />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="stressLevel"
            label="Nível de Estresse"
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
              label: `${value}`,
              value: value,
            }))}
            compact
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="musclePains.value"
            label="Possui dores articulares ou musculares frequentes?"
            options={MUSCLE_ACHES}
            renderOther={<TextInput name="musclePains.other" label="Outro" />}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="difficultiesInMoving"
            label="Possui alguma dificuldade de se movimentar?"
            options={MOVEMENT_DIFFICULTY}
            renderOther={
              <TextInput name="difficultiesInMovingOther" label="Outro" />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="pastActivities.value"
            label="Praticou alguma atividade física?"
            options={PHYSICAL_ACTIVITIES}
            renderOther={
              <TextInput name="pastActivities.other" label="Outro" />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="yourActivities.value"
            label="Pratica alguma atividade física?"
            options={PHYSICAL_ACTIVITIES}
            renderOther={
              <TextInput name="yourActivities.other" label="Outro" />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="leisureTimeActivity"
            label="Nível de Atividade Física atual"
            options={[
              { label: "Nenhuma / Muito Leve", value: 1 },
              { label: "Leve", value: 2 },
              { label: "Moderada", value: 3 },
              { label: "Intensa", value: 4 },
              { label: "Muito Intensa", value: 5 },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="workActivity"
            label="Nível de Atividade Física no Trabalho"
            options={[
              { label: "Nenhuma / Muito Leve", value: 1 },
              { label: "Leve", value: 2 },
              { label: "Moderada", value: 3 },
              { label: "Intensa", value: 4 },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

const Antropometria: React.FC<MethodsProps> = (props) => {
  const { control, setValue } = props;
  const height = useWatch({
    control,
    name: "bodyComposition.height",
  });
  const weight = useWatch({
    control,
    name: "bodyComposition.weight",
  });
  const muscleMass = useWatch({
    control,
    name: "bodyComposition.muscleMass",
  });
  const muscleMassIndex = useWatch({
    control,
    name: "bodyComposition.muscleMassIndex",
  });
  const imc = useWatch({
    control,
    name: "bodyComposition.imc",
  });

  useEffect(() => {
    if (height && weight) {
      setValue("bodyComposition.imc", weight / (height * height));
    }
  }, [height, weight, setValue]);

  useEffect(() => {
    if (height && muscleMass) {
      setValue(
        "bodyComposition.muscleMassIndex",
        muscleMass / (height * height)
      );
    }
  }, [height, muscleMass, setValue]);

  return (
    <>
      <Row>
        <Col>
          <h1 className="tech">
            Antropometria e Avaliação da Composição Corporal e da Força Muscular
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.bloodPressure"
            label="Pressão Arterial"
            format="###/### mmHg"
            suffix=" mmHg"
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.handGrip"
            label="Hand Grid"
            suffix=" kg"
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            label="Altura"
            suffix=" m"
            name="bodyComposition.height"
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            label="Peso"
            suffix=" kg"
            name="bodyComposition.weight"
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.visceralFatLevel"
            label="Nível de Gordura Visceral"
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.basalMetabolicRate"
            label="Taxa Metabólica"
            suffix=" kcal/dia"
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.references.basalMetabolicRate.min"
                  label="Min"
                  suffix=" kcal/dia"
                  compact
                />
                <NumberInput
                  name="bodyComposition.references.basalMetabolicRate.max"
                  label="Máx"
                  suffix=" kcal/dia"
                  compact
                />
              </div>
            }
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <p>
          IMC{" "}
          {Intl.NumberFormat("pt-BR", {
            maximumFractionDigits: 1,
          }).format(imc)}
        </p>
      </Row>
      <Row>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.fatMass"
            label="Massa de Gordura"
            suffix=" kg"
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.references.fatMass.min"
                  label="Min"
                  suffix=" kg"
                  compact
                />
                <NumberInput
                  name="bodyComposition.references.fatMass.max"
                  label="Máx"
                  suffix=" kg"
                  compact
                />
              </div>
            }
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.bodyWater"
            label="Água Corporal Total"
            suffix=" litros"
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.references.bodyWater.min"
                  label="Min"
                  suffix=" litros"
                  compact
                />
                <NumberInput
                  name="bodyComposition.references.bodyWater.max"
                  label="Máx"
                  suffix=" litros"
                  compact
                />
              </div>
            }
          />
        </Col>
        <Col xs="12" sm="12" lg="4">
          <NumberInput
            name="bodyComposition.freeMass"
            label="Massa Livre de Gordura"
            suffix=" kg"
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.references.freeMass.min"
                  label="Min"
                  suffix=" kg"
                  compact
                />
                <NumberInput
                  name="bodyComposition.references.freeMass.max"
                  label="Máx"
                  suffix=" kg"
                  compact
                />
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" lg="6">
          <NumberInput
            name="bodyComposition.bodyFatPercentage"
            label="Percentual de Gordura"
            suffix=" %"
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.references.bodyFatPercentage.min"
                  label="Min"
                  suffix=" %"
                  compact
                />
                <NumberInput
                  name="bodyComposition.references.bodyFatPercentage.max"
                  label="Máx"
                  suffix=" %"
                  compact
                />
              </div>
            }
          />
        </Col>
        <Col xs="12" sm="12" lg="6">
          <NumberInput
            name="bodyComposition.muscleMass"
            label="Massa Muscular Esquelética"
            suffix=" kg"
            renderOther={
              <Select
                name="bodyComposition.references.muscleMass"
                label="Classificação"
                options={["Abaixo", "Adequado"]}
                compact
              />
            }
          />
        </Col>
      </Row>

      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <p>
          IMME{" "}
          {Intl.NumberFormat("pt-BR", {
            maximumFractionDigits: 1,
          }).format(muscleMassIndex)}
        </p>
      </Row>

      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <Col xs="12" sm="4" lg="4">
          <NumberInput
            name="bodyComposition.bicep"
            label="Circunferências dos Braços"
            suffix=" cm"
            hidden
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.bicepLeft"
                  label="Esquerdo"
                  suffix=" cm"
                  compact
                />
                <NumberInput
                  name="bodyComposition.bicepRight"
                  label="Direito"
                  suffix=" cm"
                  compact
                />
              </div>
            }
          />
          <NumberInput
            name="bodyComposition.midThigh"
            label="Circunferências das Coxas"
            suffix=" cm"
            hidden
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.midThighLeft"
                  label="Esquerda"
                  suffix=" cm"
                  compact
                />
                <NumberInput
                  name="bodyComposition.midThighRight"
                  label="Direita"
                  suffix=" cm"
                  compact
                />
              </div>
            }
          />
          <NumberInput
            name="bodyComposition.calf"
            label="Circunferências das Panturrilhas"
            suffix=" cm"
            hidden
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.calfLeft"
                  label="Esquerda"
                  suffix=" cm"
                  compact
                />
                <NumberInput
                  name="bodyComposition.calfRight"
                  label="Direita"
                  suffix=" cm"
                  compact
                />
              </div>
            }
          />
        </Col>
        <Col xs="12" sm="4" lg="4">
          <img src={MuscleSVG} alt="Muscles" className="musclesImg" />
        </Col>
        <Col xs="12" sm="4" lg="4">
          <NumberInput
            name="bodyComposition.torax"
            label="Circunferência Torácica"
            suffix=" cm"
          />
          <NumberInput
            name="bodyComposition.waistAbdominal"
            label="Circunferência Abdominal"
            suffix=" cm"
            hidden
            renderOther={
              <div style={{ display: "flex" }}>
                <NumberInput
                  name="bodyComposition.waistAbdominalStyku"
                  label="Scanner"
                  suffix=" cm"
                  compact
                />
                <NumberInput
                  name="bodyComposition.waistAbdominal"
                  label="Manual"
                  suffix=" cm"
                  compact
                />
              </div>
            }
          />
          <NumberInput
            name="bodyComposition.hip"
            label="Circunferência do Quadril"
            suffix=" cm"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="circumferencesObservations"
            label="Observações sobre as circunferências"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>
    </>
  );
};

const Challenges: React.FC<MethodsProps> = (props) => {
  const { setValue } = props;
  const { challenges, stepChallenges } = useContext(SystemContext);
  const { acceptedChallenges } = useContext(ClientContext);

  useEffect(() => {
    setValue("challenges", acceptedChallenges);
  }, [acceptedChallenges, setValue]);

  // useEffect(() => {
  //   stepChallenges.forEach((c) => {
  //     if (acceptedChallenges.includes(c._id)) {
  //       console.log("steps");
  //       setValue("steps", c._id);
  //     }
  //   });
  // }, [stepChallenges, acceptedChallenges, setValue]);

  return (
    <>
      <Row>
        <Col>
          <h1 className="tech">Desafios</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckGroup
            name="challenges"
            label="Desafios do cliente"
            options={challenges.map((challenge) => ({
              label: `${challenge.name} - ${challenge.translation}`,
              value: challenge._id,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioGroup
            name="steps"
            label="Desafios de passos"
            options={stepChallenges.map((challenge) => ({
              label: `${challenge.translation} - ${challenge.description}`,
              value: challenge._id,
            }))}
          />
        </Col>
      </Row>
    </>
  );
};

const Metas: React.FC<MethodsProps> = (props) => {
  const { control, setValue } = props;
  const { goal, updateGoal } = useContext(ClientContext);
  const caloriesRange = useWatch({
    control,
    name: "goals.caloriesRange",
    defaultValue: "0,0",
  });
  const suggestionWeight = useWatch({
    control,
    name: "goals.suggestionWeight",
    defaultValue: 0,
  });
  const caloriesNih = useWatch({
    control,
    name: "goals.caloriesNih",
    defaultValue: 0,
  });

  const weight = useWatch({
    control,
    name: "bodyComposition.weight",
    defaultValue: 0,
  });
  const height = useWatch({
    control,
    name: "bodyComposition.height",
    defaultValue: 0,
  });
  const imc = useWatch({
    control,
    name: "bodyComposition.imc",
    defaultValue: 0,
  });
  const leisureTimeActivity = useWatch({
    control,
    name: "leisureTimeActivity",
    defaultValue: 1,
  });
  const workActivity = useWatch({
    control,
    name: "workActivity",
    defaultValue: 1,
  });

  const data = useWatch({
    control,
  });

  useEffect(() => {
    updateGoal({
      leisureTimeActivity,
      workActivity,
      weight,
      goalWeight: weight,
      height,
      imc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, weight, imc, workActivity, leisureTimeActivity]);

  useEffect(() => {
    const { goals } = data;
    const { weight } = goals || {};
    setValue("goals.suggestionWeight", goal.suggestionWeight);
    setValue("goals.caloriesNih", goal.caloriesNih);
    if (!weight) {
      setValue("goals.weight", goal.weight);
      setValue(
        "goals.caloriesRange",
        `${goal.minCalories},${goal.maxCalories}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal]);

  useEffect(() => {
    try {
      const [minCalories, maxCalories] = caloriesRange.split(",");
      setValue("goals.minCalories", Number(minCalories));
      setValue("goals.maxCalories", Number(maxCalories));
    } catch (e) {}
  }, [caloriesRange, setValue]);

  return (
    <>
      <Row>
        <Col>
          <h1 className="tech">Metas</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            name="weeklyAerobicMinutes"
            label="Atividade Aeróbica"
            suffix=" minutos semanais"
          />
        </Col>
        <Col>
          <NumberInput
            name="weeklyForceTimes"
            label="Atividade de Força"
            suffix=" vezes por semana"
          />
        </Col>
        <Col>
          <Select
            name="levelYourCurrentActivities"
            label="Nível Atual de Atividade Física"
            options={[
              { label: "Adaptação", value: "Adaptação" },
              { label: "Iniciante", value: "Iniciante" },
              { label: "Intermediário", value: "Intermediário" },
              { label: "Avançado", value: "Avançado" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput name="goals.weight" label="Meta de Peso" suffix=" kg" />
          <label style={{ paddingLeft: 17, color: "var(--text-light)" }}>
            Peso sugerido: {suggestionWeight}
          </label>
        </Col>
        <Col>
          <DateInput name="goals.goalDate" label="Data para atingir" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            name="goals.caloriesRange"
            options={[
              [1000, 1200],
              [1300, 1500],
              [1600, 1800],
              [1900, 2100],
              [2200, 2400],
              [2500, 2700],
              [2800, 3000],
              [3100, 3300],
              [3400, 3600],
              [3700, 3900],
            ].map(([min, max]) => ({
              label: `${min}-${max} Kcal`,
              value: `${min},${max}`,
            }))}
            label="Consumo calórico"
          />
          <label style={{ paddingLeft: 17, color: "var(--text-light)" }}>
            Consumo calórico sugerido: {caloriesNih}
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput name="goalObservations" label="Observações das metas" />
        </Col>
      </Row>
    </>
  );
};

const Uploads: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Row>
        <Col>
          <h1 className="tech">Upload de arquivos</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadFiles
            name="stykuMeasure"
            label="Upload de Arquivos Styku"
            files={[
              {
                name: "body_back",
                label: "Body Back",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_BACK")
                  );
                },
              },
              {
                name: "body_front",
                label: "Body Front",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_FRONT")
                  );
                },
              },
              {
                name: "body_right",
                label: "Body Right",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_RIGHT")
                  );
                },
              },
              {
                name: "body_left",
                label: "Body Left",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_LEFT")
                  );
                },
              },
              {
                name: "silhouette_front",
                label: "Silhouette Front",
                valid: (filename: string) => {
                  return (
                    filename &&
                    filename.toUpperCase().includes("SILHOUETTE_FRONT")
                  );
                },
              },
              {
                name: "silhouette_left",
                label: "Silhouette Left",
                valid: (filename: string) => {
                  return (
                    filename &&
                    filename.toUpperCase().includes("SILHOUETTE_LEFT")
                  );
                },
              },
              // {
              //   name: "body_scan",
              //   label: "3D Body (.obj)",
              //   valid: (filename) => {
              //     return filename && filename.toUpperCase().includes(".OBJ");
              //   },
              // },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

const Observations: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Row>
        <Col>
          <h1 className="tech">Observações</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput name="observations" label="Observações" />
        </Col>
      </Row>
    </>
  );
};

const Anamnesis: React.FC = () => {
  const methods = useForm();
  const { reset, control } = methods;
  const {
    saveAnamnesis,
    setAnamnesisType,
    currentAnamnesis,
    client,
  } = useContext(ClientContext);
  const data = useWatch({ control });
  const id = useWatch({ control, name: "_id", defaultValue: undefined });

  useEffect(() => {
    setAnamnesisType("tech");
  }, [setAnamnesisType]);

  useEffect(() => {
    reset({ ...currentAnamnesis, client: client._id });
  }, [currentAnamnesis, client, reset]);

  useEffect(() => {
    const { goals, ...form } = data;
    Object.keys(goals || {}).forEach((key) => {
      if (goals[key] === "undefined" || goals[key] === null) {
        goals[key] = undefined;
      }
    });
    if (data._id) {
      saveAnamnesis({ goal: goals, form }, "tech");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <FormProvider {...methods}>
      <form>
        {id && (
          <>
            <InformacoesGerais {...methods} />
            <Antropometria {...methods} />
            <Uploads {...methods} />
            <Challenges {...methods} />
            <Metas {...methods} />
            <Observations {...methods} />
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default Anamnesis;
