import React, { InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import "./styles.scoped.scss";

interface Option {
  label: string;
  value: string | number;
}

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  suffix?: string;
  format?: string;
  options?: Option[];
  renderOther?: React.ReactNode;
  compact?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { label, name } = props;
  const { control } = useFormContext();

  const transform = {
    output: (value: string) => {
      if (!value) return 0;
      const number = value
        .replace(props.suffix || "", "")
        .replace(/[./A-Za-z %/]+/g, "")
        .replace(",", ".");
      return isNaN(Number(number))
        ? value.replace(props.suffix || "", "").trim()
        : Number(number);
    },
  };

  return (
    <Controller
      render={({ field }) => (
        <div
          className={classNames({
            container: true,
            compact: props.compact,
          })}
        >
          <label>{label}</label>
          {props.hidden !== true && (
            <NumberFormat
              className="numberInput"
              decimalSeparator=","
              thousandSeparator="."
              {...field}
              format={props.format}
              suffix={props.suffix}
              placeholder={`__ ${props.suffix || ""}`}
              onChange={(e) => {
                //@ts-ignore
                field.onChange(transform.output(e.target.value));
              }}
            />
          )}
          {props.renderOther}
        </div>
      )}
      control={control}
      name={name}
    />
  );
};

export default NumberInput;
