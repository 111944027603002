import axios from "../middlewares/axios";

class MealsService {
  static getWeekMeals(clientId, day) {
    const url = `/meals/weekMeals?client=${clientId}&day=${day}`;
    return axios.get(url);
  }

  static generateWeekMeals(clientId) {
    const url = `/meals/weekMeals/generate?client=${clientId}`;
    return axios.get(url);
  }

  static update(id, data) {
    const url = `/meals/weekMeals/update/${id}`;
    return axios.put(url, data);
  }
}

export default MealsService;
