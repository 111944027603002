import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useField } from "@unform/core";
import { Form } from "@unform/web";
import { Scope } from "@unform/core";
import _ from "lodash";
import {
  H1,
  Collapse,
  DateInput,
  TextInput,
  NumberInput,
  Options,
  Deseases,
  Medicines,
  Loading,
  UploadFiles,
  LeftPanel,
  Exam,
} from "../../components";
import { Row, Col } from "reactstrap";
import {
  Container,
  FooterContainer,
  SaveButton,
  Title,
  Subtitle,
  ListContainer,
  ItemContainer,
  RemoveIcon,
} from "./styles";
import { selectToggled } from "../../redux/ducks/Client/selectors";
import { selectLoading } from "../../redux/ducks/Anamnesis/selectors";
import { LABORATORY_EXAMS, CARDIOLOGY_EXAMS } from "../../utils/exams";
import { AddButton, AddIcon } from "../../components/FoodList/styles";
import ClientContext from "../../context/ClientContext";

const ExamsRequest = ({ name, onChange }) => {
  const [open, setOpen] = useState(false);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [values, setValues] = useState(defaultValue || []);
  const ref = useRef(defaultValue || []);
  const printRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: null,
      getValue: () => {
        return ref.current;
      },
      clearValue: () => {
        ref.current.value = [];
      },
      setValue: (_r, value) => {
        ref.current = value;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  useEffect(() => {
    if (ref.current !== values) {
      onChange();
    }
    ref.current = values;
  }, [values, onChange]);

  return (
    <>
      <div style={{ padding: 17 }}>
        <Row>
          {values.map((exam) => (
            <Col xs={12} sm={6}>
              <div
                style={{
                  marginBottom: 30,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RemoveIcon
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setValues(values.filter((e) => e.name !== exam.name));
                  }}
                />
                <Title
                  style={{ color: "var(--purple-light)", marginBottom: 0 }}
                >
                  {exam.label}
                </Title>
              </div>
            </Col>
          ))}
        </Row>
        <div ref={printRef} style={{ display: "none" }}>
          <Row>
            <Col>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: 50,
                }}
              >
                <strong>Solicitação de Exames</strong>
              </p>
            </Col>
          </Row>
          {values.map((exam) => (
            <Row key={exam.name}>
              <Col>
                <p>{exam.label}</p>
              </Col>
            </Row>
          ))}
        </div>
        <Row>
          <Col>
            <AddButton
              style={{ position: "initial" }}
              onClick={() => setOpen(!open)}
            >
              <AddIcon />
              Adicionar exames
            </AddButton>
          </Col>
          <Col>
            <AddButton
              style={{ position: "initial" }}
              onClick={() => {
                var myWindow = window.open(
                  "",
                  "Solicitação de exames",
                  `height=${window.innerHeight},width=${window.innerWidth}`
                );
                myWindow.document.write(
                  "<html><head><title>Haux Clinic</title>"
                );
                myWindow.document.write("</head><body >");
                myWindow.document.write(printRef.current.innerHTML);
                myWindow.document.write("</body></html>");
                myWindow.document.close();
                myWindow.focus();
                myWindow.print();
                myWindow.close();
              }}
            >
              Imprimir
            </AddButton>
          </Col>
        </Row>
      </div>
      <LeftPanel open={open} onClose={() => setOpen(false)}>
        <Title>Exames</Title>
        <Subtitle>Selecione os exames que deseja solicitar</Subtitle>

        <ListContainer>
          {[...LABORATORY_EXAMS, ...CARDIOLOGY_EXAMS]
            .filter((e) => !values.includes(e))
            .map((exam) => (
              <ItemContainer
                key={exam.name}
                onClick={() => setValues([...values, exam])}
              >
                <Title style={{ color: "var(--purple-light)" }}>
                  {exam.label}
                </Title>
                <Subtitle>{exam.suffix}</Subtitle>
              </ItemContainer>
            ))}
        </ListContainer>
      </LeftPanel>
    </>
  );
};

const Med = () => {
  const {
    saveAnamnesis,
    setAnamnesisType,
    currentAnamnesis,
    client,
  } = useContext(ClientContext);
  const clientToggled = useSelector(selectToggled);
  const loading = useSelector(selectLoading);
  const [currentData, setCurrentData] = useState({});
  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);
  const otherAssociatedDiseases = useRef([]);
  const medicines = useRef([]);

  useEffect(() => {
    setAnamnesisType("med");
  }, [setAnamnesisType]);

  useEffect(() => {
    if (loading === false) {
      setTouched(false);
    }
  }, [loading]);

  useEffect(() => {
    setCurrentData(formRef.current.getData());
  }, [currentAnamnesis]);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      const values = {
        ...data,
        _id: currentAnamnesis._id,
        client: client._id,
        responsibleProfessional: "5f523484e912ff4a31221602",
        otherAssociatedDiseases: otherAssociatedDiseases.current,
        medicines: medicines.current,
      };

      await saveAnamnesis(values, "med");
      setTouched(false);
    } catch (err) {}
  };

  const renderDiagnosis = (
    name,
    label,
    year,
    symptoms,
    dateLast,
    doctor,
    diagnosis,
    observation
  ) => (
    <Row style={{ marginBottom: 20 }}>
      <Col>
        <Options
          name={`${name}.have`}
          label={label}
          multiple={false}
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
          onChange={() => {
            setTouched(true);
            setCurrentData(formRef.current.getData());
          }}
        />
      </Col>
      <Col
        style={{
          opacity: _.get(currentData, `${name}.have`) ? 1 : 0.2,
          pointerEvents: _.get(currentData, `${name}.have`) ? "all" : "none",
        }}
      >
        <Row>
          {year && (
            <Col xs={4}>
              <NumberInput
                name={`${name}.year`}
                label="Ano de diagnóstico"
                format="####"
                compact
              />
            </Col>
          )}
          {symptoms && (
            <Col>
              <TextInput
                name={`${name}.symptoms`}
                label="Sinais e sintomas"
                placeholder="Digite aqui os sinais"
                compact
              />
            </Col>
          )}
        </Row>
        <Row>
          {dateLast && (
            <Col>
              <DateInput
                name={`${name}.dateLast`}
                label="Data última"
                compact
              />
            </Col>
          )}
          {doctor && (
            <Col>
              <TextInput
                name={`${name}.doctor`}
                label="Nome Médico"
                placeholder="Digite o nome do médico"
                compact
              />
            </Col>
          )}
        </Row>
        <Row>
          {diagnosis && (
            <Col>
              <TextInput
                name={`${name}.diagnosis`}
                label="Diagnóstico"
                placeholder="Digite o diagnóstico"
                compact
              />
            </Col>
          )}
        </Row>
        <Row>
          {observation && (
            <Col>
              <TextInput
                name={`${name}.observation`}
                label="Observações"
                placeholder="Digite as observações"
                compact
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );

  const renderAvaliacaoAtual = () => (
    <>
      <Row>
        <Col xs="12" sm="12" lg="4">
          <DateInput name="consultationDate" label="Data da Consulta" />
        </Col>
      </Row>
      <Row>
        <Col>
          <H1 color="med">Avaliação Atual</H1>
        </Col>
      </Row>
      <Collapse title="GERAL">
        <Row>
          <Col>
            <Options
              name="mainComplaint.complaints"
              label="Queixa principal"
              otherId="mainComplaint.others"
              otherLabel="Outras"
              multiple={true}
              options={[
                { label: "Obesidade", value: "Obesidade" },
                { label: "Diabetes", value: "Diabetes" },
                { label: "Tireoide", value: "Tireoide" },
                { label: "Outra", value: "Outra" },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <TextInput
              name="historyComplaint"
              label="História pregressa da moléstia"
              placeholder="Digite aqui a história"
            />
          </Col>
        </Row>
        {renderDiagnosis("headache", "Cefaleia?", false, true)}
        {renderDiagnosis("thyropathy", "Tireopatia?", true, true)}
        <Row
          style={{
            opacity: _.get(currentData, "thyropathy.have") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "thyropathy.have")
              ? "all"
              : "none",
          }}
        >
          <Col>
            <Options
              name="thyropathy.complaints"
              label="Qual tireopatia?"
              otherId="thyropathy.others"
              otherLabel="Outras"
              multiple={true}
              options={[
                {
                  label: "Hipotireoidismo",
                  value: "Hipotireoidismo",
                },
                {
                  label: "Hipertireoidismo",
                  value: "Hipertireoidismo",
                },
                {
                  label: "Nódulos",
                  value: "Nódulos",
                },
                {
                  label: "Câncer",
                  value: "Câncer",
                },
                {
                  label: "Outras",
                  value: "Outras",
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>

        {renderDiagnosis(
          "cardiologistAppointment",
          "Consulta cardiologista?",
          false,
          false,
          true,
          true,
          false,
          true
        )}

        <Row
          style={{
            opacity: _.get(currentData, "cardiologistAppointment.have")
              ? 1
              : 0.2,
            pointerEvents: _.get(currentData, "cardiologistAppointment.have")
              ? "all"
              : "none",
          }}
        >
          <Col>
            <Options
              name="cardiologistAppointment.complaints"
              label="Queixas cardíacas"
              otherId="cardiologistAppointment.others"
              otherLabel="Outras"
              options={[
                {
                  label: "Dor pré-cordial",
                  value: "Dor pré-cordial",
                },
                {
                  label: "Palpitações",
                  value: "Palpitações",
                },
                {
                  label: "Dispnéia",
                  value: "Dispnéia",
                },
                {
                  label: "Hipertensão",
                  value: "Hipertensão",
                },
                {
                  label: "Colesterol elevado",
                  value: "Colesterol elevado",
                },
                {
                  label: "Triglicerídeos",
                  value: "Triglicerídeos",
                },
                {
                  label: "Outras",
                  value: "Outras",
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse title="GASTRO-INTESTINAL">
        <Scope path="digestionAndFoodFunction">
          <Row>
            <Col>
              <Options
                name="symptoms"
                label="Queixas gastroesofágicas?"
                otherId="others"
                otherLabel="Outras"
                options={[
                  "Não",
                  "Refluxo",
                  "Gastrite",
                  "Náuseas",
                  "Vômitos",
                  "Outras",
                ].map((e) => ({
                  label: e,
                  value: e,
                }))}
                onChange={() => setTouched(true)}
              />
            </Col>
            <Col>
              <TextInput
                name="observations"
                label="Observações sobre as queixas gastroesofágicas?"
                placeholder="Digite aqui as observações"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Options
                name="intestinalFunction"
                label="Como é sua função intestinal?"
                multiple={false}
                options={["Constipado", "Normal", "Diarreia"].map((e) => ({
                  label: e,
                  value: e,
                }))}
                onChange={() => setTouched(true)}
              />
            </Col>
            <Col>
              <TextInput
                name="intestinalFunctionObservations"
                label="Observações sobre a função intestinal"
                placeholder="Digite aqui as observações"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberInput
                name="evaluationNumber"
                label="Quantas evacuações na semana"
                suffix=" evacuações semanais"
              />
            </Col>
          </Row>
        </Scope>
        {renderDiagnosis(
          "endoscopy",
          "Endoscopia?",
          false,
          false,
          true,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "colonoscopy",
          "Colonoscopia?",
          false,
          false,
          true,
          false,
          true,
          true
        )}
      </Collapse>

      <Collapse title="UROLOGISTA-GINECOLOGISTA">
        <Row>
          <Col>
            <Options
              name="urinarySymptoms.value"
              label="Sintomas urinários?"
              otherId="urinarySymptoms.other"
              otherLabel="Outros"
              options={["Não", "Disúria", "Poliúria", "Outros"].map((e) => ({
                label: e,
                value: e,
              }))}
              onChange={() => setTouched(true)}
            />
          </Col>
          <Col>
            <Options
              name="urinaryInfection"
              label="Infecção urinária em repetição?"
              multiple={false}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              onChange={() => setTouched(true)}
            />
          </Col>
        </Row>

        {renderDiagnosis(
          "urologistAppointment",
          "Consulta urologista?",
          false,
          false,
          true,
          true,
          false,
          true
        )}

        <Row>
          <Col>
            <Options
              name="sexualPotency"
              label="Potência sexual"
              multiple={false}
              options={[
                { label: "Normal", value: "Normal" },
                { label: "Diminuída", value: "Diminuída" },
              ]}
              onChange={() => setTouched(true)}
            />
          </Col>
          <Col>
            <Options
              name="libido"
              label="Libido"
              multiple={false}
              options={[
                { label: "Normal", value: "Normal" },
                { label: "Diminuída", value: "Diminuída" },
              ]}
              onChange={() => setTouched(true)}
            />
          </Col>
        </Row>

        {renderDiagnosis(
          "gynecologistAppointment",
          "Consulta ginecologista?",
          false,
          false,
          true,
          true,
          false,
          true
        )}

        <Row>
          <Col>
            <NumberInput name="menarche" label="Menarca" format="## anos" />
          </Col>
          <Col>
            <NumberInput name="menopause" label="Menopausa" format="## anos" />
          </Col>
        </Row>
        <Row>
          <Col>
            <NumberInput name="pregnancies" label="Gestações" format="##" />
          </Col>
          <Col>
            <NumberInput name="abortions" label="Abortos" format="##" />
          </Col>
          <Col>
            <NumberInput name="childBirths" label="Partos" format="##" />
          </Col>
        </Row>

        <Row>
          <Col>
            <Options
              name="menstrualCycles.value"
              label="Ciclos menstruais"
              otherLabel="Outras"
              otherId="menstrualCycles.other"
              options={[
                { label: "Normal", value: "Normal" },
                { label: "Amenorreia", value: "Amenorreia" },
                { label: "Oligomenorreia", value: "Oligomenorreia" },
                { label: "Hipomenorreia", value: "Hipomenorreia" },
                { label: "Menorragia", value: "Menorragia" },
                { label: "Metrorragia", value: "Metrorragia" },
                { label: "Espaniomenorreia", value: "Espaniomenorreia" },
                { label: "Outras", value: "Outras" },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Options
              name="macrosomalFetuses"
              label="Fetos macrossômicos?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="contraceptive.value"
              label="Método anticoncepcional"
              otherLabel="Outro"
              otherId="contraceptive.other"
              options={[
                { label: "Anticoncepcional", value: "Anticoncepcional" },
                { label: "DIU", value: "DIU" },
                { label: "Laqueadura", value: "Laqueadura" },
                { label: "Vasectomia", value: "Vasectomia" },
                { label: "Tabela", value: "Tabela" },
                { label: "Coito interrompido", value: "Coito interrompido" },
                { label: "Implante", value: "Implante" },
                { label: "Outro", value: "Outro" },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="hormonalReplacement.use"
              label="Reposição hormonal?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
                setCurrentData(formRef.current.getData());
              }}
            />
          </Col>
          <Col
            style={{
              opacity: _.get(currentData, "hormonalReplacement.use") ? 1 : 0.2,
              pointerEvents: _.get(currentData, "hormonalReplacement.use")
                ? "all"
                : "none",
            }}
          >
            <TextInput
              name="hormonalReplacement.time"
              label="Tempo de uso"
              placeholder="Descreva aqui o tempo de uso"
              compact
            />
          </Col>
        </Row>

        {renderDiagnosis(
          "vulvovaginitisBalanoposthitis",
          "Vulvovaginite/Balanopostite?",
          false,
          false,
          false,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "acne",
          "Acne?",
          false,
          false,
          false,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "hirsutism",
          "Hirsutismo?",
          false,
          false,
          false,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "lossOfHair",
          "Queda de cabelo?",
          false,
          false,
          false,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "hotFlushes",
          "Fogachos?",
          false,
          false,
          false,
          false,
          true,
          true
        )}

        {renderDiagnosis(
          "mammaryComplaints",
          "Queixas mamárias?",
          false,
          false,
          false,
          false,
          false,
          true
        )}

        {renderDiagnosis(
          "preventiveExams",
          "Exames preventivos urológicos/ginecológicos?",
          false,
          false,
          true,
          false,
          true,
          false
        )}
        <Row>
          <Col>
            <TextInput
              name="generalSymptoms"
              label="Outros sintomas sistêmicos"
              placeholder="Fraqueza/astenia/fadiga crônica/edema…"
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );

  const renderAntecedentes = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Antecedentes e Hábitos</H1>
        </Col>
      </Row>

      <Row>
        <Col>
          <TextInput
            name="habits.previousDiseases"
            label="Doenças anteriores"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Options
            name="habits.cigarette.use"
            label="Cigarros?"
            multiple={false}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "habits.cigarette.use") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "habits.cigarette.use")
              ? "all"
              : "none",
          }}
        >
          <Row>
            <Col>
              <NumberInput
                name="habits.cigarette.number"
                label="Número por dia"
                format="## cigarros por dia"
                compact
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="habits.cigarette.usageTime"
                label="Tempo de uso"
                placeholder="Digite aqui"
                compact
              />
            </Col>
            <Col>
              <TextInput
                name="habits.cigarette.abandonTime"
                label="Tempo abandono"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Options
            name="habits.alcohol.use"
            label="Álcool?"
            multiple={false}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "habits.alcohol.use") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "habits.alcohol.use")
              ? "all"
              : "none",
          }}
        >
          <Row>
            <Col>
              <TextInput
                name="habits.alcohol.typeOf"
                label="Tipo de bebida consumida"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberInput
                name="habits.alcohol.amount"
                label="Quantidade (ml/dia)"
                format="#### ml por dia"
                compact
              />
            </Col>
            <Col>
              <NumberInput
                name="habits.alcohol.frequency"
                label="Vezes por semana"
                format="#### vezes"
                compact
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Options
            name="habits.drugs.use"
            label="Drogas?"
            multiple={false}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "habits.drugs.use") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "habits.drugs.use")
              ? "all"
              : "none",
          }}
        >
          <Row>
            <Col>
              <TextInput
                name="habits.drugs.typeOf"
                label="Tipo"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="habits.drugs.amount"
                label="Dose"
                placeholder="Digite aqui"
                compact
              />
            </Col>
            <Col>
              <TextInput
                name="habits.drugs.frequency"
                label="Frequência"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="habits.drugs.usageTime"
                label="Tempo de uso"
                placeholder="Digite aqui"
                compact
              />
            </Col>
            <Col>
              <TextInput
                name="habits.drugs.abandonTime"
                label="Tempo abandono"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Options
            name="habits.surgery.done"
            label="Cirurgias?"
            multiple={false}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
            onChange={() => {
              setTouched(true);
              setCurrentData(formRef.current.getData());
            }}
          />
        </Col>
        <Col
          style={{
            opacity: _.get(currentData, "habits.surgery.done") ? 1 : 0.2,
            pointerEvents: _.get(currentData, "habits.surgery.done")
              ? "all"
              : "none",
          }}
        >
          <Row>
            <Col>
              <TextInput
                name="habits.surgery.typeOf"
                label="Tipo"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="habits.surgery.time"
                label="Tempo de realização"
                placeholder="Digite aqui"
                compact
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <TextInput
            name="habits.other"
            label="Outros antecedentes"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>
    </>
  );

  const renderDoencasAssociadas = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Doenças associadas</H1>
        </Col>
      </Row>
      <Collapse title="PSICOLOGIA/PSIQUIATRIA">
        <Row>
          <Col>
            <Options
              name="psychology.values"
              label=""
              otherId="psychology.other"
              otherLabel="Outras psicopatias"
              options={[
                "Depressão",
                "Ansiedade",
                "Déficit de atenção",
                "Bipolaridade",
                "Outras",
              ].map((e) => ({
                label: e,
                value: e,
              }))}
              onChange={() => setTouched(true)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="psychology.currentTreatment.do"
              label="Tratamento atual"
              multiple={false}
              options={["Sim", "Não"].map((e) => ({
                label: e,
                value: e,
              }))}
              onChange={() => setTouched(true)}
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <TextInput
                  name="psychology.currentTreatment.psychologist"
                  label="Nome médico psicólogo"
                  placeholder="Digite aqui o nome"
                  compact
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  name="psychology.currentTreatment.psychiatrist"
                  label="Nome médico psiquiatra"
                  placeholder="Digite aqui o nome"
                  compact
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Collapse>

      <Collapse title="SONO">
        <Row>
          <Col>
            <NumberInput
              label="Horas de Sono"
              name="rest.sleepHours"
              format="## horas"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="rest.snore"
              label="Ronca?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <Options
              name="rest.apnea"
              label="Apneia?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="rest.polysomnography"
              label="Fez polissonografia?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
                setCurrentData(formRef.current.getData());
              }}
            />
          </Col>
          <Col
            style={{
              opacity: _.get(currentData, "rest.polysomnography") ? 1 : 0.2,
              pointerEvents: _.get(currentData, "rest.polysomnography")
                ? "all"
                : "none",
            }}
          >
            <DateInput
              name="rest.polysomnographyDate"
              label="Data da última"
              compact
            />
            <TextInput
              name="rest.polysomnographyObservation"
              label="Obeservações sobre a polissonografia"
              compact
            />
          </Col>
        </Row>
      </Collapse>

      <Collapse title="SINTOMAS MÚSCULO-ESQUELÉTICOS">
        {renderDiagnosis(
          "jointSymptoms",
          "Sintomas articulares/musculares?",
          false,
          false,
          false,
          false,
          false,
          true
        )}

        {renderDiagnosis(
          "ostrearthrosis",
          "Osteoartrose?",
          false,
          false,
          false,
          false,
          false,
          true
        )}

        {renderDiagnosis(
          "otherRheumatologicalDiseases",
          "Outras doenças reumatológicas?",
          false,
          false,
          false,
          false,
          false,
          true
        )}
      </Collapse>

      <Collapse title="DOENÇAS HEPÁTICAS">
        <Row>
          <Col>
            <Options
              name="hepaticDisease.steatosis"
              label="Esteatose?"
              multiple={false}
              options={[
                {
                  label: "Não",
                  value: "Não",
                },
                {
                  label: "Grau I",
                  value: "Grau I",
                },
                {
                  label: "Grau II",
                  value: "Grau II",
                },
                {
                  label: "Grau III",
                  value: "Grau III",
                },
                {
                  label: "Grau não referido",
                  value: "Grau não referido",
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <Options
              name="hepaticDisease.cholelithiasis"
              label="Colelitíase?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options
              name="hepaticDisease.viralHepatitis"
              label="Hepatite viral?"
              otherId="hepaticDisease.otherViralHepatitis"
              otherLabel="Outra"
              multiple={false}
              options={[
                { label: "A", value: "A" },
                { label: "B", value: "B" },
                { label: "C", value: "C" },
                { label: "Outra", value: "Outra" },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
      </Collapse>

      <Collapse title="DOENÇAS CARDIOVASCULARES">
        <Row>
          <Col>
            <Options
              name="cardiovasculaDisease.arterialHypertension"
              label="Hipertensão arterial?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <Options
              name="cardiovasculaDisease.ischemicHeartDisease"
              label="Cardiopatia isquêmica?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Options
              name="cardiovasculaDisease.arterialDisease"
              label="Doença arterial periférica, carotídea e cerebral?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <Options
              name="cardiovasculaDisease.hypercholesterolemia"
              label="Hipercolesterolemia?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Options
              name="cardiovasculaDisease.hypertriglyceridemia"
              label="Hipertrigliceridemia?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
          <Col>
            <Options
              name="cardiovasculaDisease.lowHDL"
              label="HDL baixo?"
              multiple={false}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
              onChange={() => {
                setTouched(true);
              }}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );

  const renderOutrasDoencas = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Outras doenças associadas</H1>
        </Col>
      </Row>
      <Collapse title="COMPLICAÇÕES CRÔNICAS DO DIABETES">
        <Row>
          <Col>
            <Deseases
              values={currentAnamnesis.otherAssociatedDiseases}
              onChange={(items) => (otherAssociatedDiseases.current = items)}
              deseases={[
                {
                  label: "Pé diabético",
                  value: "Pé diabético",
                },
                {
                  label: "Oftalmopatia",
                  value: "Oftalmopatia",
                },
                {
                  label: "Cardiovascular",
                  value: "Cardiovascular",
                },
                {
                  label: "Nefropatia",
                  value: "Nefropatia",
                },
                {
                  label: "Neuropatia periférica",
                  value: "Neuropatia periférica",
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              name="otherComplications"
              label="Outras complicações"
              placeholder="Digite aqui"
            />
          </Col>
        </Row>
      </Collapse>

      <Collapse title="MEDICAMENTOS">
        <Row>
          <Col>
            <Medicines
              values={currentAnamnesis.medicines}
              onChange={(items) => (medicines.current = items)}
              medicines={[
                {
                  label: "Insulinas",
                  value: "Insulinas",
                },
                {
                  label: "Antihipertensivos",
                  value: "Antihipertensivos",
                },
                {
                  label: "Hipolipemiante",
                  value: "Hipolipemiante",
                },
                {
                  label: "Antidiabético oral",
                  value: "Antidiabético oral",
                },
                {
                  label: "Antiagregantes",
                  value: "Antiagregantes",
                },
                {
                  label: "Psiquiátrica",
                  value: "Psiquiátrica",
                },
                {
                  label: "Indutor de sono",
                  value: "Indutor de sono",
                },
                {
                  label: "Antiobesidade",
                  value: "Antiobesidade",
                },
                {
                  label: "Outros medicamentos",
                  value: "Outros medicamentos",
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              name="diabetesAndObesityMedicines"
              label="Medicações já utilizadas para diabetes e obesidade"
              placeholder="Digite aqui"
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );

  const renderAntecedentesFamiliares = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Antecedentes Familiares</H1>
        </Col>
      </Row>
      <Collapse title="GERAL">
        {[
          {
            label: "Diabetes?",
            id: "familyBackground.diabetes.have",
            memberId: "familyBackground.diabetes.whichFamilyMember",
          },
          {
            label: "Hipertensão arterial?",
            id: "familyBackground.arterialHypertension.have",
            memberId: "familyBackground.arterialHypertension.whichFamilyMember",
          },
          {
            label: "Obesidade?",
            id: "familyBackground.obesity.have",
            memberId: "familyBackground.obesity.whichFamilyMember",
          },
          {
            label: "Cardiopatia isquêmica?",
            id: "familyBackground.ischemicHeartDisease.have",
            memberId: "familyBackground.ischemicHeartDisease.whichFamilyMember",
          },
          {
            label: "AVC?",
            id: "familyBackground.stroke.have",
            memberId: "familyBackground.stroke.whichFamilyMember",
          },
          {
            label: "Tromboembolismo?",
            id: "familyBackground.thromboembolism.have",
            memberId: "familyBackground.thromboembolism.whichFamilyMember",
          },
          {
            label: "Hemocromatose?",
            id: "familyBackground.hemochromatosis.have",
            memberId: "familyBackground.hemochromatosis.whichFamilyMember",
          },
          {
            label: "Câncer?",
            id: "familyBackground.cancer.have",
            memberId: "familyBackground.cancer.whichFamilyMember",
          },
          {
            label: "Outra?",
            id: "familyBackground.other.have",
            memberId: "familyBackground.other.whichFamilyMember",
            morbidityId: "familyBackground.other.morbidity",
          },
        ].map((option) => (
          <Row>
            <Col>
              <Options
                name={option.id}
                label={option.label}
                multiple={false}
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
                onChange={() => {
                  setTouched(true);
                  setCurrentData(formRef.current.getData());
                }}
              />
            </Col>
            <Col
              style={{
                opacity: _.get(currentData, option.id) ? 1 : 0.2,
                pointerEvents: _.get(currentData, option.id) ? "all" : "none",
              }}
            >
              {option.morbidityId && (
                <TextInput
                  name={option.morbidityId}
                  placeholder="Digite aqui..."
                  label="Qual morbidade?"
                />
              )}
              <TextInput
                name={option.memberId}
                placeholder="Digite aqui..."
                label="Qual familiar?"
              />
            </Col>
          </Row>
        ))}
      </Collapse>
    </>
  );

  const renderExamsRequest = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Solicitação de exames</H1>
        </Col>
      </Row>
      <ExamsRequest name="examRequest" onChange={() => setTouched(true)} />
    </>
  );

  const renderExameFisico = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Exame Físico</H1>
        </Col>
      </Row>
      <Collapse title="GERAL">
        <Row>
          <Col>
            <TextInput
              name="physicalExams.acanthosisNigricans"
              label="Acantose nigricans"
              placeholder="Digite aqui"
            />
          </Col>
          <Col>
            <TextInput
              name="physicalExams.hirsutism"
              label="Hirsutismo"
              placeholder="Digite aqui"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              name="physicalExams.thyroidPalpation"
              label="Palpação da tireóide"
              placeholder="Digite aqui"
            />
          </Col>
          <Col>
            <TextInput
              name="physicalExams.abdominalPalpation"
              label="Palpação do abdomem"
              placeholder="Digite aqui"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              name="physicalExams.lowerMembers"
              label="Membros inferiores"
              placeholder="Digite aqui"
            />
          </Col>
          <Col>
            <TextInput
              name="physicalExams.otherPositiveTestData"
              label="Outros dados positivos do exame"
              placeholder="Digite aqui"
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );

  const renderExamesComplementares = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Exames Complementares</H1>
        </Col>
      </Row>
      <Scope path="exams">
        <Collapse title="LABORATÓRIO">
          <Row>
            {LABORATORY_EXAMS.map((exam) => (
              <Col key={exam.name} xs={12} xl={12} sm={12}>
                <Exam
                  label={exam.label}
                  name={exam.name}
                  suffix={exam.suffix}
                  references={exam.references}
                />
              </Col>
            ))}
          </Row>
        </Collapse>

        <Collapse title="CÁRDIOLÓGICOS">
          <Row>
            {CARDIOLOGY_EXAMS.map((exam) => (
              <Col key={exam.name} xs={12} xl={6} sm={12}>
                <Exam
                  label={exam.label}
                  name={exam.name}
                  suffix={exam.suffix}
                  references={exam.references}
                />
              </Col>
            ))}
          </Row>
        </Collapse>

        <Collapse title="RADIOLOGIA">
          <UploadFiles
            name="teste"
            label="Upload de Arquivos de Exame de Radiologia"
            files={[
              {
                name: "inbody",
                label: "Exame",
                valid: () => true,
              },
            ]}
          />
        </Collapse>
      </Scope>
    </>
  );

  const renderDiagnosticos = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Diagnósticos</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="medicalDiagnoses"
            label="Diagnósticos médicos"
            placeholder="Digite aqui"
          />
        </Col>
        <Col>
          <TextInput
            name="hauxDiagnoses"
            label="Diagnóstico para a equipe Haux"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>
    </>
  );

  const renderConduta = () => (
    <>
      <Row>
        <Col>
          <H1 color="med">Conduta</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            name="conduct"
            label="Condutas"
            placeholder="Digite aqui"
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" lg="4">
          <DateInput name="returnDate" label="Previsão de retorno" />
        </Col>
      </Row>
    </>
  );

  const renderSave = () => (
    <FooterContainer visible={touched} clientToggled={clientToggled}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  return (
    <Form
      key={currentAnamnesis._id}
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        ...currentAnamnesis,
      }}
      onChange={() => setTouched(true)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <Container>
        {renderAvaliacaoAtual()}
        {renderAntecedentes()}
        {renderDoencasAssociadas()}
        {renderOutrasDoencas()}
        {renderAntecedentesFamiliares()}
        {renderExamsRequest()}
        {renderExameFisico()}
        {renderExamesComplementares()}
        {renderDiagnosticos()}
        {renderConduta()}
      </Container>
      {renderSave()}
    </Form>
  );
};

export default Med;
